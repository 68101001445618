import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
// import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDvDyaYrVDk9wkkIpfuva3Ww9SUQ7poGrA",
  authDomain: "denbaba-59dbd.firebaseapp.com",
  databaseURL: "https://denbaba-59dbd.firebaseio.com",
  projectId: "denbaba-59dbd",
  storageBucket: "denbaba-59dbd.appspot.com",
  messagingSenderId: "915177806652",
  appId: "1:915177806652:web:ba54417bff9f88c25d5c39",
  measurementId: "G-PRK141LY64",
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
// export const analytics = firebaseApp.analytics();
export const firestoreTimestamp = firebase.firestore.Timestamp;
export const db = firebaseApp.firestore();
export const auth = firebaseApp.auth();
