import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import RightIcon from "react-feather/dist/icons/chevron-right";

import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { clearCart, removeProduct } from "../store/cart/actions";
import CartContent from "./CartContent";

const CartDialog = (props) => {
  const {
    openCartModal,
    setOpenCartModal,
    totalItems,
    totalPrice,
    delivery,
    selectedZone,
  } = props;
  const theme = useTheme();

  const { minAmount } = selectedZone || {};

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const history = useHistory();

  return (
    <Dialog
      fullWidth
      fullScreen={fullScreen}
      onClose={() => setOpenCartModal(false)}
      open={openCartModal && totalItems > 0}
      className="flex flex-col justify-between select-none"
    >
      <DialogTitle className="flex-1">Winkelwagen</DialogTitle>
      <DialogContent>
        <div className="overflow-y-auto">
          <CartContent editable={true} />
        </div>
      </DialogContent>
      <DialogActions className="flex flex-row justify-between items-center my-4">
        <p className="flex-grow text-right mr-4 font-hairline">Totaal</p>
        <p className="font-bold text-2xl">
          {(totalPrice / 100).toFixed(2).replace(".", ",")}&euro;
        </p>
      </DialogActions>
      {delivery && totalPrice < minAmount ? (
        <div className="p-4">
          <p className="text-sm text-right text-yellow-500">
            Minimum bestelbedrag is{" "}
            <span className="font-bold">
              {(minAmount / 100).toFixed(2).replace(".", ",")}
              &euro;
            </span>
          </p>
        </div>
      ) : (
        ""
      )}

      <DialogActions className="mb-8">
        <Button
          className="flex"
          variant="contained"
          size="large"
          onClick={() => setOpenCartModal(false)}
        >
          Sluit
        </Button>
        <Button
          className="flex"
          disabled={delivery && totalPrice < minAmount}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => history.push("/confirm")}
          endIcon={<RightIcon />}
        >
          Bestel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  ...state.cart,
  ...state.order,
  ...state.session,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clear: clearCart,
      removeItem: removeProduct,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CartDialog);
