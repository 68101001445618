import { db } from "../firebase";

export const getZones = () => {
  return db
    .collection("zones")
    .where("isEnabled", "==", true)
    .orderBy("minAmount")
    .orderBy("name")
    .get()
    .then((qs) =>
      qs.docs.map((ds) => {
        return { id: ds.id, ...ds.data() };
      })
    );
};
