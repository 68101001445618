import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { useAuthState } from "../contexts/AuthContextProvider";

const ProtectedRoute = ({ children }) => {
  const { getUser, isLoading } = useAuthState();

  const { isAuthenticated } = useSelector((state) => state.session);

  const currentUser = getUser();

  if (isLoading) {
    return <p>Loading....</p>;
  }

  if (currentUser === null && !isLoading) {
    return <Redirect to="login" />;
  }

  return children;
};

export default ProtectedRoute;
