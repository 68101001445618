import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  TextField,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  Button,
  IconButton,
  CircularProgress,
  Snackbar,
  MenuItem,
  Paper,
  FormHelperText,
} from "@material-ui/core";

import { v4 as uuidv4 } from "uuid";

import { useForm, Controller } from "react-hook-form";

import { useHistory } from "react-router-dom";
import CartContent from "./CartContent";
import { updateOrderInDB } from "../firebase/db/order";
import { clearCart, setCoupon } from "../store/cart/actions";
import { bindActionCreators } from "redux";
import axios from "axios";
import { db } from "../firebase/firebase";
import { Trash, ChevronRight } from "react-feather";
import Alert from "./Alert";
import { removeError } from "../store/order/actions";
import { red } from "@material-ui/core/colors";

const Confirm = (props) => {
  const {
    items,
    totalPrice,
    delivery,
    coupon,
    setDiscount,
    isOpen,
    failedOrder,
    paymentError,
    clearError,
    selectedZone,
  } = props;

  const { minAmount } = selectedZone || {};

  const [times, setTimes] = useState([]);
  const [code, setCode] = useState(undefined);
  const [couponError, setCouponError] = useState(false);
  const [loadingTimes, setLoadingTimes] = useState(true);
  const [openError, setOpenError] = useState(paymentError !== null);

  const [errorMessage, setErrorMessage] = useState(paymentError);

  const { register, handleSubmit, errors, control } = useForm();

  const history = useHistory();

  useEffect(() => {
    //analytics.logEvent("begin_checkout");
    axios
      .post("https://europe-west1-denbaba-59dbd.cloudfunctions.net/openToday", {
        delivery,
      })
      .then((response) => {
        if (response && response.data) {
          setTimes(response.data.times);
          setLoadingTimes(false);
        }
      });
  }, [delivery]);

  const checkCouponCode = (coup) => {
    if (coup && coup.label) {
      const trimmedCode = coup.label.replace(/\s+/g, "").toLowerCase();
      return db
        .collection("coupons")
        .doc(trimmedCode)
        .get()
        .then((ds) => {
          if (ds.exists) {
            return true;
          } else {
            return false;
          }
        })
        .catch(() => false);
    } else {
      return Promise.resolve(false);
    }
  };

  const validateFields = (data) => {
    const validatedData = data;
    let orderData = null;
    if (delivery) {
      orderData = {
        ...validatedData,
        postalCode: selectedZone.postalCode,
      };
    } else {
      orderData = { ...validatedData };
    }
    setLoadingTimes(true);

    const newId = uuidv4();

    checkCouponCode(coupon)
      .then((valid) => {
        const dbPromise = updateOrderInDB(
          failedOrder ? failedOrder.id : newId,
          {
            ...orderData,
            totalPrice: valid ? totalPrice - coupon.discount : totalPrice,
            coupon: valid ? coupon : null,
            items,
            delivery,
            status: orderData.payment === "online" ? "pending" : "new",
          }
        );
        return Promise.all([dbPromise, valid]);
      })
      .then((result) => {
        const isValid = result[1];
        if (orderData.payment === "online") {
          return axios.post(
            "https://europe-west1-denbaba-59dbd.cloudfunctions.net/createPayment",
            {
              amt: `${(
                (isValid ? totalPrice - coupon.discount : totalPrice) / 100
              ).toFixed(2)}`,
              orderId: failedOrder ? failedOrder.id : newId,
            }
          );
        } else {
          return Promise.resolve({
            online: false,
            orderId: failedOrder ? failedOrder.id : newId,
          });
        }
      })
      .then((response) => {
        setLoadingTimes(false);
        if (response.online === false) {
          history.push(`/confirmation/${response.orderId}`);
        } else {
          if (
            response.data &&
            response.data.url !== null &&
            response.data.error === null
          )
            console.log(response.data);
          window.location.replace(response.data.url);
        }
      })
      .catch((err) => console.log(err)); //analytics.logEvent("exception", { description: err }));
  };

  useEffect(() => {
    if (
      (delivery && minAmount && totalPrice < minAmount) ||
      !isOpen ||
      items.length < 1
    ) {
      history.push("/catalog");
    }
  }, [delivery, history, isOpen, items.length, minAmount, totalPrice]);

  const handleCoupon = () => {
    if (code && code !== "") {
      const trimmedCode = code.replace(/\s+/g, "").toLowerCase();
      db.collection("coupons")
        .doc(trimmedCode)
        .get()
        .then((cp) => {
          if (cp.exists) {
            setCouponError(false);

            if (cp.data().minAmount) {
              if (totalPrice > cp.data().minAmount) {
                setDiscount(cp.data());
                return;
              } else {
                setCouponError(true);
                setErrorMessage(
                  `Het minimum bedrag om deze coupon te gebruiken is ${(
                    cp.data().minAmount / 100
                  )
                    .toFixed(2)
                    .replace(".", ",")}€`
                );
                setOpenError(true);
                setCode(null);
              }
              return;
            } else {
              setDiscount(cp.data());
            }

            //const coup = cp.data();

            // if (coup.product) {
            //   const { product } = coup;

            //   console.log("FREE PRODUCT: ", product);
            // }
          } else {
            setCouponError(true);
            setErrorMessage("Deze coupon is niet geldig. Probeer opnieuw.");
            setOpenError(true);
            setCode(null);
          }
        });
    }
  };

  return (
    <div className="flex-grow flex flex-col justify-around items-center p-4">
      <Snackbar
        open={openError}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={6000}
        onClose={() => {
          setOpenError(false);
          clearError();
        }}>
        <Alert
          onClose={() => {
            setOpenError(false);
            clearError();
          }}
          severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
      <div className="flex-1 w-full">
        <p className="text-2xl font-thin uppercase text-gray-400 mb-4">
          Winkelmandje
        </p>
        <div className="h-64 overflow-y-auto">
          <CartContent editable={false} />
        </div>
        <div className="px-4">
          {coupon === null ? (
            <span className="flex flex-row justify-between items-center mb-4">
              <TextField
                className="mr-2 flex-grow"
                placeholder="Coupon code"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                error={couponError}
              />
              <Button
                className="focus:outline-none ml-4"
                variant="outlined"
                color="primary"
                disabled={code === null && code === ""}
                onClick={() => handleCoupon()}>
                Toevoegen
              </Button>
              {/* <IconButton
                className="focus:outline-none"
                color="primary"
                disabled={code === null && code === ""}
                onClick={() => handleCoupon()}
              >
                <CheckIcon />
              </IconButton> */}
            </span>
          ) : (
            <>
              <span className="flex flex-row justify-between items-center">
                <p className="flex-grow text-right mr-2 font-hairline">
                  Subtotaal
                </p>
                <p className="font-bold text-2xl text-right">
                  {(totalPrice / 100).toFixed(2).replace(".", ",")} &euro;
                </p>
              </span>
              <span className="flex flex-row justify-between items-center">
                <IconButton color="secondary" onClick={() => setDiscount(null)}>
                  <Trash />
                </IconButton>
                <p className="flex-grow text-right mr-2 font-hairline">
                  {`Coupon ${coupon.label}`}
                </p>
                <p className="font-bold text-2xl text-right text-red-600">
                  {(0 - coupon.discount / 100).toFixed(2).replace(".", ",")}{" "}
                  &euro;
                </p>
              </span>

              <hr />
            </>
          )}

          <span className="flex flex-row justify-between items-center">
            <p className="flex-grow text-right mr-2 font-hairline">Totaal</p>
            <p className="font-bold text-2xl text-right">
              {(
                (coupon === null ? totalPrice : totalPrice - coupon.discount) /
                100
              )
                .toFixed(2)
                .replace(".", ",")}{" "}
              &euro;
            </p>
          </span>
        </div>
      </div>
      <div className="flex-1 w-full mt-8">
        <p className="text-2xl font-thin uppercase text-gray-400 mb-4">
          Gegevens
        </p>
        <form
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(validateFields)}>
          <div className="flex flex-wrap">
            <TextField
              autoComplete="off"
              className="w-full md:w-1/2"
              required
              id="firstName"
              name="firstName"
              label="Voornaam"
              margin="normal"
              value={failedOrder ? failedOrder.firstName : undefined}
              inputRef={register({ required: true })}
              error={errors.firstName !== undefined}
              helperText={
                errors.firstName ? "Voornaam is verplicht in te vullen" : ""
              }
            />
            <TextField
              className="w-full md:w-1/2"
              required
              id="lastName"
              name="lastName"
              label="Achternaam"
              autoComplete="off"
              margin="normal"
              value={failedOrder ? failedOrder.lastName : undefined}
              inputRef={register({ required: true })}
              error={errors.lastName !== undefined}
              helperText={
                errors.lastName ? "Achternaam is verplicht in te vullen" : ""
              }
            />
          </div>
          {delivery && (
            <div className="flex flex-wrap">
              <TextField
                className="w-full md:w-1/2 mr-4"
                autoComplete="off"
                required
                id="street"
                name="street"
                label="Straat"
                type="text"
                margin="normal"
                value={
                  failedOrder && failedOrder.delivery
                    ? failedOrder.street
                    : undefined
                }
                inputRef={register({ required: true })}
                error={errors.street !== undefined}
                helperText={
                  errors.street ? "Straat is verplicht in te vullen" : ""
                }
              />

              <TextField
                className="w-1/2 md:w-1/4 mr-4"
                required
                autoComplete="off"
                id="number"
                name="number"
                label="Huisnummer"
                type="text"
                margin="normal"
                value={
                  failedOrder && failedOrder.delivery
                    ? failedOrder.number
                    : undefined
                }
                inputRef={register({ required: true })}
                error={errors.number !== undefined}
                helperText={
                  errors.number ? "Huisnummer is verplicht in te vullen" : ""
                }
              />

              <TextField
                className="w-1/2 md:w-1/4"
                id="bus"
                autoComplete="off"
                name="bus"
                label="Bus"
                type="text"
                margin="normal"
                value={
                  failedOrder && failedOrder.delivery
                    ? failedOrder.bus
                    : undefined
                }
                inputRef={register}
              />
              <TextField
                className="w-full"
                disabled
                required
                id="city"
                name="city"
                autoComplete="off"
                label="Gemeente"
                type="text"
                margin="normal"
                value={selectedZone.name}
                inputRef={register({
                  required: "Gemeente is verplicht in te vullen",
                })}
                error={errors.city !== undefined}
                helperText={errors.city?.message}
              />
              {/* <Controller
                name='city'
                rules={{ required: 'Gemeente is een verplicht veld' }}
                control={control}
                defaultValue={
                  failedOrder && failedOrder.delivery
                    ? failedOrder.city
                    : zones[0].name
                }
                as={
                  <TextField
                    className='w-full'
                    select
                    label='Gemeente'
                    autoComplete='off'
                    margin='normal'
                    required
                    error={errors.city !== undefined}
                    helperText={errors.city?.message}
                  >
                    {zones.map((z) => (
                      <MenuItem key={z.id} value={z.name}>
                        {z.name}
                      </MenuItem>
                    ))}
                  </TextField>
                }
              /> */}
            </div>
          )}
          <div className="flex flex-wrap">
            <TextField
              className="w-full md:w-1/2"
              required
              id="email"
              name="email"
              autoComplete="off"
              label="Email"
              type="email"
              margin="normal"
              value={failedOrder ? failedOrder.email : undefined}
              inputRef={register({
                required: "Emailadres is verplicht in te vullen",
                pattern: {
                  value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                  message: "Dit is geen geldig emailadres",
                },
              })}
              error={errors.email !== undefined}
              helperText={errors.email?.message}
            />

            <TextField
              required
              className="w-full md:w-1/2"
              id="telephone"
              name="telephone"
              autoComplete="off"
              label="Telefoon"
              type="text"
              margin="normal"
              value={failedOrder ? failedOrder.telephone : undefined}
              inputRef={register({
                required: "Telefoon is verplicht in te vullen",
              })}
              error={errors.telephone !== undefined}
              helperText={errors.telephone?.message}
            />
          </div>
          <div className="flex flex-wrap mb-8">
            <TextField
              className="w-full md:w-1/2"
              id="comment"
              name="comment"
              autoComplete="off"
              label="Opmerkingen"
              multiline
              margin="normal"
              value={failedOrder ? failedOrder.comment : undefined}
              inputRef={register}
            />
            {times?.length > 0 ? (
              <Controller
                name="time"
                rules={{ required: "Tijd is een verplicht veld" }}
                control={control}
                defaultValue={failedOrder ? failedOrder.time : times[0]}
                as={
                  <TextField
                    className="w-full md:w-1/2"
                    select
                    label="Tijd"
                    autoComplete="off"
                    margin="normal"
                    required
                    error={errors.time !== undefined}
                    helperText={errors.time?.message}>
                    {times.map((t) =>
                      t === "asap" ? (
                        <MenuItem key={t} value={t}>
                          Zo snel mogelijk
                        </MenuItem>
                      ) : (
                        <MenuItem key={t} value={t}>
                          {t}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                }
              />
            ) : (
              <TextField
                className="w-full md:w-1/2"
                label="Tijd"
                disabled
                autoComplete="off"
                required
                margin="normal"
              />
            )}
          </div>
          <div className="flex w-full md:w-1/2">
            <FormControl component="fieldset" className="flex-1">
              <FormLabel component="legend" className="mb-4">
                Betaalwijze
              </FormLabel>
              <FormHelperText style={{ color: red[500] }}>
                {errors.payment?.message}
              </FormHelperText>
              <RadioGroup name="payment" className="flex flex-wrap">
                <Paper className="p-4 mb-4">
                  <FormControlLabel
                    value="cash"
                    name="payment"
                    control={<Radio color="primary" />}
                    inputRef={register({
                      required: "Gelieve een betalingsmethode te kiezen",
                    })}
                    label="Cash"
                  />
                </Paper>
                <Paper className="p-4">
                  <FormControlLabel
                    name="payment"
                    inputRef={register({
                      required: "Gelieve een betalingsmethode te kiezen",
                    })}
                    value="online"
                    control={<Radio color="primary" />}
                    label="Online"
                  />
                </Paper>
              </RadioGroup>
            </FormControl>
          </div>
          <div className="w-full mt-8">
            <div className="flex flex-row justify-end">
              <Button
                disabled={loadingTimes || !isOpen || !times || times.length < 1}
                className="flex w-64"
                variant="contained"
                type="submit"
                color="primary"
                endIcon={isOpen && !loadingTimes ? <ChevronRight /> : null}>
                {loadingTimes ? (
                  <CircularProgress />
                ) : isOpen && times && times.length > 0 ? (
                  "Bestel"
                ) : (
                  "Gesloten"
                )}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.cart,
  ...state.session,
  ...state.order,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clear: clearCart,
      setDiscount: setCoupon,
      clearError: removeError,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Confirm);
