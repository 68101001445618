import { SET_ZONES, SET_SELECTED_ZONE, SET_LOGIN } from "./actions";

const initialState = {
  isAuthenticated: false,
  selectedZone: {
    minAmount: 0,
  },
  zones: [],
};

export const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOGIN: {
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    }
    case SET_SELECTED_ZONE: {
      const zone = action.payload;
      return {
        ...state,
        selectedZone: zone,
      };
    }
    case SET_ZONES: {
      const zones = action.payload;
      return {
        ...state,
        zones: zones,
      };
    }
    default:
      return state;
  }
};
