import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";
import React from "react";

const ExtrasOptions = (props) => {
  const { extras, delivery, setSelectedExtras, selectedExtras } = props;

  const returnExtraPriceParagraph = (price) => (
    <p className="font-hairline flex-shrink-0 ml-1">
      +({(price / 100).toFixed(2).replace(".", ",")} &euro;)
    </p>
  );

  return (
    <div>
      {extras.map((eg, idx) => (
        <div key={eg.id} className="mb-8">
          <FormControl component="form" key={eg.id}>
            <FormLabel component="legend">{eg.name}</FormLabel>
            {eg.max && (
              <FormHelperText>
                <p className="text-yellow-500 font-semibold">
                  Maximaal {eg.max} keuze(s)
                </p>
              </FormHelperText>
            )}
            {eg.isRequired && (
              <FormHelperText>
                <p className="text-red-600 font-semibold">
                  Verplicht minstens 1 keuze te maken
                </p>
              </FormHelperText>
            )}
            {eg.hasSubCategories ? (
              <>
                <FormLabel component="legend">{eg.primaryLabel}</FormLabel>
                <FormGroup row>
                  {eg.extras
                    .filter((e) =>
                      e.relatedTags.some((el) => el === eg.primaryId)
                    )
                    .map((e) => (
                      <FormControlLabel
                        key={e.id}
                        label={
                          <div className="flex flex-row ">
                            <p>{e.name}</p>
                            {/* IS THE OPTION INCLUDED ? */}

                            {eg.included === false &&
                              // DELIVERY OR TAKEOUT
                              (delivery
                                ? e.priceDelivery !== undefined &&
                                  e.priceDelivery > 0 &&
                                  (eg.includedPriceDelivery
                                    ? e.priceDelivery -
                                        eg.includedPriceDelivery >
                                        0 &&
                                      returnExtraPriceParagraph(
                                        e.priceDelivery -
                                          eg.includedPriceDelivery
                                      )
                                    : returnExtraPriceParagraph(
                                        e.priceDelivery
                                      ))
                                : e.price !== undefined &&
                                  e.price > 0 &&
                                  (eg.includedPrice
                                    ? e.price - eg.includedPrice > 0 &&
                                      returnExtraPriceParagraph(
                                        e.price - eg.includedPrice
                                      )
                                    : returnExtraPriceParagraph(e.price)))}
                          </div>
                        }
                        control={
                          <Checkbox
                            color="primary"
                            checked={
                              selectedExtras[eg.id]
                                ? selectedExtras[eg.id].extras[e.id]
                                  ? selectedExtras[eg.id].extras[e.id].checked
                                  : false
                                : false
                            }
                            onChange={(event) => {
                              const oldSelectedExtras = selectedExtras[eg.id]
                                ? {
                                    ...selectedExtras[eg.id].extras,
                                  }
                                : {};

                              const { [e.id]: _, ...newData } =
                                oldSelectedExtras;

                              if (eg.max !== undefined) {
                                if (
                                  eg.max >
                                  Object.keys(newData).filter(
                                    (k) => newData[k].checked
                                  ).length
                                ) {
                                  setSelectedExtras({
                                    ...selectedExtras,
                                    [eg.id]: {
                                      isRequired: eg?.isRequired || false,
                                      max: eg?.max,
                                      extras: {
                                        ...newData,
                                        [event.target.name]: {
                                          name: e.name,
                                          category: eg.id,
                                          order: e.order ? e.order : 0,
                                          printOrder: eg?.printOrder || 0,
                                          price:
                                            !eg.included &&
                                            e.price !== undefined
                                              ? delivery
                                                ? eg.includedPriceDelivery
                                                  ? e.priceDelivery -
                                                    eg.includedPriceDelivery
                                                  : e.priceDelivery
                                                : eg.includedPrice
                                                ? e.price - eg.includedPrice
                                                : e.price
                                              : 0,
                                          checked: event.target.checked,
                                        },
                                      },
                                    },
                                  });
                                }
                              } else {
                                setSelectedExtras({
                                  ...selectedExtras,
                                  [eg.id]: {
                                    isRequired: eg?.isRequired || false,
                                    max: eg?.max,
                                    extras: {
                                      ...newData,
                                      [event.target.name]: {
                                        name: e.name,
                                        category: eg.id,
                                        order: e.order ? e.order : 0,
                                        printOrder: eg?.printOrder || 0,
                                        price:
                                          !eg.included && e.price !== undefined
                                            ? delivery
                                              ? eg.includedPriceDelivery
                                                ? e.priceDelivery -
                                                  eg.includedPriceDelivery
                                                : e.priceDelivery
                                              : eg.includedPrice
                                              ? e.price - eg.includedPrice
                                              : e.price
                                            : 0,
                                        checked: event.target.checked,
                                      },
                                    },
                                  },
                                });
                              }
                            }}
                            name={e.id}
                          />
                        }
                      />
                    ))}
                </FormGroup>
                <>
                  <FormLabel component="legend">{eg.subLabel}</FormLabel>
                  <FormGroup row>
                    {eg.extras
                      .filter((e) => !e.relatedTags.includes(eg.primaryId))
                      .map((e) => (
                        <FormControlLabel
                          key={e.id}
                          label={
                            <div className="flex flex-row ">
                              <p>{e.name}</p>
                              {/* IS THE OPTION INCLUDED ? */}

                              {eg.included === false &&
                                // DELIVERY OR TAKEOUT
                                (delivery
                                  ? e.priceDelivery !== undefined &&
                                    e.priceDelivery > 0 &&
                                    (eg.includedPriceDelivery
                                      ? e.priceDelivery -
                                          eg.includedPriceDelivery >
                                          0 &&
                                        returnExtraPriceParagraph(
                                          e.priceDelivery -
                                            eg.includedPriceDelivery
                                        )
                                      : returnExtraPriceParagraph(
                                          e.priceDelivery
                                        ))
                                  : e.price !== undefined &&
                                    e.price > 0 &&
                                    (eg.includedPrice
                                      ? e.price - eg.includedPrice > 0 &&
                                        returnExtraPriceParagraph(
                                          e.price - eg.includedPrice
                                        )
                                      : returnExtraPriceParagraph(e.price)))}
                            </div>
                          }
                          control={
                            <Checkbox
                              color="primary"
                              checked={
                                selectedExtras[eg.id]
                                  ? selectedExtras[eg.id].extras[e.id]
                                    ? selectedExtras[eg.id].extras[e.id].checked
                                    : false
                                  : false
                              }
                              onChange={(event) => {
                                const oldSelectedExtras = selectedExtras[eg.id]
                                  ? {
                                      ...selectedExtras[eg.id].extras,
                                    }
                                  : {};

                                const { [e.id]: _, ...newData } =
                                  oldSelectedExtras;

                                if (eg.max !== undefined) {
                                  if (
                                    eg.max >
                                    Object.keys(newData).filter(
                                      (k) => newData[k].checked
                                    ).length
                                  ) {
                                    setSelectedExtras({
                                      ...selectedExtras,
                                      [eg.id]: {
                                        isRequired: eg?.isRequired || false,
                                        max: eg?.max,
                                        extras: {
                                          ...newData,
                                          [event.target.name]: {
                                            name: e.name,
                                            category: eg.id,
                                            order: e.order ? e.order : 0,
                                            printOrder: eg?.printOrder || 0,
                                            price:
                                              !eg.included &&
                                              e.price !== undefined
                                                ? delivery
                                                  ? eg.includedPriceDelivery
                                                    ? e.priceDelivery -
                                                      eg.includedPriceDelivery
                                                    : e.priceDelivery
                                                  : eg.includedPrice
                                                  ? e.price - eg.includedPrice
                                                  : e.price
                                                : 0,
                                            checked: event.target.checked,
                                          },
                                        },
                                      },
                                    });
                                  }
                                } else {
                                  setSelectedExtras({
                                    ...selectedExtras,
                                    [eg.id]: {
                                      isRequired: eg?.isRequired || false,
                                      max: eg?.max,
                                      extras: {
                                        ...newData,
                                        [event.target.name]: {
                                          name: e.name,
                                          category: eg.id,
                                          order: e.order ? e.order : 0,
                                          printOrder: eg?.printOrder || 0,
                                          price:
                                            !eg.included &&
                                            e.price !== undefined
                                              ? delivery
                                                ? eg.includedPriceDelivery
                                                  ? e.priceDelivery -
                                                    eg.includedPriceDelivery
                                                  : e.priceDelivery
                                                : eg.includedPrice
                                                ? e.price - eg.includedPrice
                                                : e.price
                                              : 0,
                                          checked: event.target.checked,
                                        },
                                      },
                                    },
                                  });
                                }
                              }}
                              name={e.id}
                            />
                          }
                        />
                      ))}
                  </FormGroup>
                </>
              </>
            ) : (
              <FormGroup row>
                {" "}
                {eg.extras.map((e) => (
                  <FormControlLabel
                    key={e.id}
                    label={
                      <div className="flex flex-row ">
                        <p>{e.name}</p>
                        {/* IS THE OPTION INCLUDED ? */}

                        {eg.included === false &&
                          // DELIVERY OR TAKEOUT
                          (delivery
                            ? e.priceDelivery !== undefined &&
                              e.priceDelivery > 0 &&
                              (eg.includedPriceDelivery
                                ? e.priceDelivery - eg.includedPriceDelivery >
                                    0 &&
                                  returnExtraPriceParagraph(
                                    e.priceDelivery - eg.includedPriceDelivery
                                  )
                                : returnExtraPriceParagraph(e.priceDelivery))
                            : e.price !== undefined &&
                              e.price > 0 &&
                              (eg.includedPrice
                                ? e.price - eg.includedPrice > 0 &&
                                  returnExtraPriceParagraph(
                                    e.price - eg.includedPrice
                                  )
                                : returnExtraPriceParagraph(e.price)))}
                      </div>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        checked={
                          selectedExtras[eg.id]
                            ? selectedExtras[eg.id].extras[e.id]
                              ? selectedExtras[eg.id].extras[e.id].checked
                              : false
                            : false
                        }
                        onChange={(event) => {
                          const oldSelectedExtras = selectedExtras[eg.id]
                            ? {
                                ...selectedExtras[eg.id].extras,
                              }
                            : {};

                          const { [e.id]: _, ...newData } = oldSelectedExtras;

                          if (eg.max !== undefined) {
                            if (
                              eg.max >
                              Object.keys(newData).filter(
                                (k) => newData[k].checked
                              ).length
                            ) {
                              setSelectedExtras({
                                ...selectedExtras,
                                [eg.id]: {
                                  isRequired: eg?.isRequired || false,
                                  max: eg?.max,
                                  extras: {
                                    ...newData,
                                    [event.target.name]: {
                                      name: e.name,
                                      category: eg.id,
                                      order: e.order ? e.order : 0,
                                      printOrder: eg?.printOrder || 0,
                                      price:
                                        !eg.included && e.price !== undefined
                                          ? delivery
                                            ? eg.includedPriceDelivery
                                              ? e.priceDelivery -
                                                eg.includedPriceDelivery
                                              : e.priceDelivery
                                            : eg.includedPrice
                                            ? e.price - eg.includedPrice
                                            : e.price
                                          : 0,
                                      checked: event.target.checked,
                                    },
                                  },
                                },
                              });
                            }
                          } else {
                            setSelectedExtras({
                              ...selectedExtras,
                              [eg.id]: {
                                isRequired: eg?.isRequired || false,
                                max: eg?.max,
                                extras: {
                                  ...newData,
                                  [event.target.name]: {
                                    name: e.name,
                                    category: eg.id,
                                    order: e.order ? e.order : 0,
                                    printOrder: eg?.printOrder || 0,
                                    price:
                                      !eg.included && e.price !== undefined
                                        ? delivery
                                          ? eg.includedPriceDelivery
                                            ? e.priceDelivery -
                                              eg.includedPriceDelivery
                                            : e.priceDelivery
                                          : eg.includedPrice
                                          ? e.price - eg.includedPrice
                                          : e.price
                                        : 0,
                                    checked: event.target.checked,
                                  },
                                },
                              },
                            });
                          }
                        }}
                        name={e.id}
                      />
                    }
                  />
                ))}
              </FormGroup>
            )}
          </FormControl>
        </div>
      ))}
    </div>
  );
};

export default ExtrasOptions;
