import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Step,
  StepContent,
  StepLabel,
  Checkbox,
  Stepper,
  CircularProgress,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

import { db } from "../firebase/firebase";

const getSteps = () => {
  return ["Menu", "Burger", "Frieten"];
};

const ExtrasStepper = (props) => {
  const {
    selectedProduct,
    delivery,
    setSelectedExtras,
    selectedExtras,
    extras,
    setExtras,
  } = props;

  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const steps = getSteps();

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    setIsLoading(true);
    if (selectedProduct) {
      //    setPrice(selectedProduct.price);
      if (selectedProduct.stepperExtras) {
        console.log("test");
        const extraGroups = selectedProduct.stepperExtras
          .map((se, idx) =>
            se.extraGroups.map((ex) => {
              return { ...ex, stepperIdx: idx };
            })
          )
          .flat();

        db.collection("products")
          .where(
            "relatedTags",
            "array-contains-any",
            extraGroups.map((ex) => ex.id)
          )
          .where("isEnabled", "==", true)
          .orderBy("extraOrder")
          .get()
          .then((qs) => {
            const extras = qs.docs.map((ds) => {
              return { ...ds.data(), id: ds.id };
            });

            const cats = extraGroups.map((ex) => {
              return {
                ...ex,
                extras: extras.filter((e) => e.relatedTags.includes(ex.id)),
              };
            });

            setExtras(cats);
            setIsLoading(false);
          });
      }
    }
  }, [selectedProduct, setExtras]);

  const returnExtraPriceParagraph = (price) => (
    <p className="font-hairline flex-shrink-0 ml-1">
      +({(price / 100).toFixed(2).replace(".", ",")} &euro;)
    </p>
  );

  return (
    <div>
      {
        isLoading ? (
          <div className="flex justify-center items-center">
            <CircularProgress size={80} disableShrink />
          </div>
        ) : (
          selectedProduct.stepperExtras && (
            <Stepper activeStep={activeStep} orientation="vertical">
              {selectedProduct.stepperExtras.map((se, index) => (
                <Step key={se.label}>
                  <StepLabel>{se.label}</StepLabel>
                  <StepContent>
                    <div>
                      {extras
                        .filter((e) => e.stepperIdx === activeStep)
                        .map((eg, idx) => (
                          <div key={eg.id} className="mb-8">
                            <FormControl component="form" key={eg.id}>
                              <FormLabel component="legend">
                                {eg.name}
                              </FormLabel>
                              {eg.max && (
                                <FormHelperText>
                                  <p className="text-yellow-500 font-semibold">
                                    Maximaal {eg.max} keuze(s)
                                  </p>
                                </FormHelperText>
                              )}
                              {eg.isRequired && (
                                <FormHelperText>
                                  <p className="text-red-600 font-semibold">
                                    Verplicht minstens 1 keuze te maken
                                  </p>
                                </FormHelperText>
                              )}
                              {eg.hasSubCategories ? (
                                <>
                                  <FormLabel component="legend">
                                    {eg.primaryLabel}
                                  </FormLabel>
                                  <FormGroup row>
                                    {eg.extras
                                      .filter((e) =>
                                        e.relatedTags.some(
                                          (el) => el === eg.primaryId
                                        )
                                      )
                                      .map((e) => (
                                        <FormControlLabel
                                          key={e.id}
                                          label={
                                            <div className="flex flex-row ">
                                              <p>{e.name}</p>
                                              {/* IS THE OPTION INCLUDED ? */}

                                              {eg.included === false &&
                                                // DELIVERY OR TAKEOUT
                                                (delivery
                                                  ? e.priceDelivery !==
                                                      undefined &&
                                                    e.priceDelivery > 0 &&
                                                    (eg.includedPriceDelivery
                                                      ? e.priceDelivery -
                                                          eg.includedPriceDelivery >
                                                          0 &&
                                                        returnExtraPriceParagraph(
                                                          e.priceDelivery -
                                                            eg.includedPriceDelivery
                                                        )
                                                      : returnExtraPriceParagraph(
                                                          e.priceDelivery
                                                        ))
                                                  : e.price !== undefined &&
                                                    e.price > 0 &&
                                                    (eg.includedPrice
                                                      ? e.price -
                                                          eg.includedPrice >
                                                          0 &&
                                                        returnExtraPriceParagraph(
                                                          e.price -
                                                            eg.includedPrice
                                                        )
                                                      : returnExtraPriceParagraph(
                                                          e.price
                                                        )))}
                                            </div>
                                          }
                                          control={
                                            <Checkbox
                                              color="primary"
                                              checked={
                                                selectedExtras[eg.id]
                                                  ? selectedExtras[eg.id]
                                                      .extras[e.id]
                                                    ? selectedExtras[eg.id]
                                                        .extras[e.id].checked
                                                    : false
                                                  : false
                                              }
                                              onChange={(event) => {
                                                const oldSelectedExtras =
                                                  selectedExtras[eg.id]
                                                    ? {
                                                        ...selectedExtras[eg.id]
                                                          .extras,
                                                      }
                                                    : {};

                                                const {
                                                  [e.id]: _,
                                                  ...newData
                                                } = oldSelectedExtras;

                                                if (eg.max !== undefined) {
                                                  if (
                                                    eg.max >
                                                    Object.keys(newData).filter(
                                                      (k) => newData[k].checked
                                                    ).length
                                                  ) {
                                                    setSelectedExtras({
                                                      ...selectedExtras,
                                                      [eg.id]: {
                                                        isRequired:
                                                          eg?.isRequired ||
                                                          false,
                                                        max: eg?.max,
                                                        extras: {
                                                          ...newData,
                                                          [event.target.name]: {
                                                            name: e.name,
                                                            stepperLabel:
                                                              se.label,
                                                            category: eg.id,
                                                            order: e.order
                                                              ? e.order
                                                              : 0,
                                                            printOrder:
                                                              eg?.printOrder ||
                                                              0,
                                                            price:
                                                              !eg.included &&
                                                              e.price !==
                                                                undefined
                                                                ? delivery
                                                                  ? eg.includedPriceDelivery
                                                                    ? e.priceDelivery -
                                                                      eg.includedPriceDelivery
                                                                    : e.priceDelivery
                                                                  : eg.includedPrice
                                                                  ? e.price -
                                                                    eg.includedPrice
                                                                  : e.price
                                                                : 0,
                                                            checked:
                                                              event.target
                                                                .checked,
                                                          },
                                                        },
                                                      },
                                                    });
                                                  }
                                                } else {
                                                  setSelectedExtras({
                                                    ...selectedExtras,
                                                    [eg.id]: {
                                                      isRequired:
                                                        eg?.isRequired || false,
                                                      max: eg?.max,
                                                      extras: {
                                                        ...newData,
                                                        [event.target.name]: {
                                                          name: e.name,
                                                          stepperLabel:
                                                            se.label,
                                                          category: eg.id,
                                                          order: e.order
                                                            ? e.order
                                                            : 0,
                                                          printOrder:
                                                            eg?.printOrder || 0,
                                                          price:
                                                            !eg.included &&
                                                            e.price !==
                                                              undefined
                                                              ? delivery
                                                                ? eg.includedPriceDelivery
                                                                  ? e.priceDelivery -
                                                                    eg.includedPriceDelivery
                                                                  : e.priceDelivery
                                                                : eg.includedPrice
                                                                ? e.price -
                                                                  eg.includedPrice
                                                                : e.price
                                                              : 0,
                                                          checked:
                                                            event.target
                                                              .checked,
                                                        },
                                                      },
                                                    },
                                                  });
                                                }
                                              }}
                                              name={e.id}
                                            />
                                          }
                                        />
                                      ))}
                                  </FormGroup>
                                  <>
                                    <FormLabel component="legend">
                                      {eg.subLabel}
                                    </FormLabel>
                                    <FormGroup row>
                                      {eg.extras
                                        .filter(
                                          (e) =>
                                            !e.relatedTags.includes(
                                              eg.primaryId
                                            )
                                        )
                                        .map((e) => (
                                          <FormControlLabel
                                            key={e.id}
                                            label={
                                              <div className="flex flex-row ">
                                                <p>{e.name}</p>
                                                {/* IS THE OPTION INCLUDED ? */}

                                                {eg.included === false &&
                                                  // DELIVERY OR TAKEOUT
                                                  (delivery
                                                    ? e.priceDelivery !==
                                                        undefined &&
                                                      e.priceDelivery > 0 &&
                                                      (eg.includedPriceDelivery
                                                        ? e.priceDelivery -
                                                            eg.includedPriceDelivery >
                                                            0 &&
                                                          returnExtraPriceParagraph(
                                                            e.priceDelivery -
                                                              eg.includedPriceDelivery
                                                          )
                                                        : returnExtraPriceParagraph(
                                                            e.priceDelivery
                                                          ))
                                                    : e.price !== undefined &&
                                                      e.price > 0 &&
                                                      (eg.includedPrice
                                                        ? e.price -
                                                            eg.includedPrice >
                                                            0 &&
                                                          returnExtraPriceParagraph(
                                                            e.price -
                                                              eg.includedPrice
                                                          )
                                                        : returnExtraPriceParagraph(
                                                            e.price
                                                          )))}
                                              </div>
                                            }
                                            control={
                                              <Checkbox
                                                color="primary"
                                                checked={
                                                  selectedExtras[eg.id]
                                                    ? selectedExtras[eg.id]
                                                        .extras[e.id]
                                                      ? selectedExtras[eg.id]
                                                          .extras[e.id].checked
                                                      : false
                                                    : false
                                                }
                                                onChange={(event) => {
                                                  const oldSelectedExtras =
                                                    selectedExtras[eg.id]
                                                      ? {
                                                          ...selectedExtras[
                                                            eg.id
                                                          ].extras,
                                                        }
                                                      : {};

                                                  const {
                                                    [e.id]: _,
                                                    ...newData
                                                  } = oldSelectedExtras;

                                                  if (eg.max !== undefined) {
                                                    if (
                                                      eg.max >
                                                      Object.keys(
                                                        newData
                                                      ).filter(
                                                        (k) =>
                                                          newData[k].checked
                                                      ).length
                                                    ) {
                                                      setSelectedExtras({
                                                        ...selectedExtras,
                                                        [eg.id]: {
                                                          isRequired:
                                                            eg?.isRequired ||
                                                            false,
                                                          max: eg?.max,
                                                          extras: {
                                                            ...newData,
                                                            [event.target.name]:
                                                              {
                                                                name: e.name,
                                                                stepperLabel:
                                                                  se.label,
                                                                category: eg.id,
                                                                order: e.order
                                                                  ? e.order
                                                                  : 0,
                                                                printOrder:
                                                                  eg?.printOrder ||
                                                                  0,
                                                                price:
                                                                  !eg.included &&
                                                                  e.price !==
                                                                    undefined
                                                                    ? delivery
                                                                      ? eg.includedPriceDelivery
                                                                        ? e.priceDelivery -
                                                                          eg.includedPriceDelivery
                                                                        : e.priceDelivery
                                                                      : eg.includedPrice
                                                                      ? e.price -
                                                                        eg.includedPrice
                                                                      : e.price
                                                                    : 0,
                                                                checked:
                                                                  event.target
                                                                    .checked,
                                                              },
                                                          },
                                                        },
                                                      });
                                                    }
                                                  } else {
                                                    setSelectedExtras({
                                                      ...selectedExtras,
                                                      [eg.id]: {
                                                        isRequired:
                                                          eg?.isRequired ||
                                                          false,
                                                        max: eg?.max,
                                                        extras: {
                                                          ...newData,
                                                          [event.target.name]: {
                                                            name: e.name,
                                                            stepperLabel:
                                                              se.label,
                                                            category: eg.id,
                                                            order: e.order
                                                              ? e.order
                                                              : 0,
                                                            printOrder:
                                                              eg?.printOrder ||
                                                              0,
                                                            price:
                                                              !eg.included &&
                                                              e.price !==
                                                                undefined
                                                                ? delivery
                                                                  ? eg.includedPriceDelivery
                                                                    ? e.priceDelivery -
                                                                      eg.includedPriceDelivery
                                                                    : e.priceDelivery
                                                                  : eg.includedPrice
                                                                  ? e.price -
                                                                    eg.includedPrice
                                                                  : e.price
                                                                : 0,
                                                            checked:
                                                              event.target
                                                                .checked,
                                                          },
                                                        },
                                                      },
                                                    });
                                                  }
                                                }}
                                                name={e.id}
                                              />
                                            }
                                          />
                                        ))}
                                    </FormGroup>
                                  </>
                                </>
                              ) : (
                                <FormGroup row>
                                  {" "}
                                  {eg.extras.map((e) => (
                                    <FormControlLabel
                                      key={e.id}
                                      label={
                                        <div className="flex flex-row ">
                                          <p>{e.name}</p>
                                          {/* IS THE OPTION INCLUDED ? */}

                                          {eg.included === false &&
                                            // DELIVERY OR TAKEOUT
                                            (delivery
                                              ? e.priceDelivery !== undefined &&
                                                e.priceDelivery > 0 &&
                                                (eg.includedPriceDelivery
                                                  ? e.priceDelivery -
                                                      eg.includedPriceDelivery >
                                                      0 &&
                                                    returnExtraPriceParagraph(
                                                      e.priceDelivery -
                                                        eg.includedPriceDelivery
                                                    )
                                                  : returnExtraPriceParagraph(
                                                      e.priceDelivery
                                                    ))
                                              : e.price !== undefined &&
                                                e.price > 0 &&
                                                (eg.includedPrice
                                                  ? e.price - eg.includedPrice >
                                                      0 &&
                                                    returnExtraPriceParagraph(
                                                      e.price - eg.includedPrice
                                                    )
                                                  : returnExtraPriceParagraph(
                                                      e.price
                                                    )))}
                                        </div>
                                      }
                                      control={
                                        <Checkbox
                                          color="primary"
                                          checked={
                                            selectedExtras[eg.id]
                                              ? selectedExtras[eg.id].extras[
                                                  e.id
                                                ]
                                                ? selectedExtras[eg.id].extras[
                                                    e.id
                                                  ].checked
                                                : false
                                              : false
                                          }
                                          onChange={(event) => {
                                            const oldSelectedExtras =
                                              selectedExtras[eg.id]
                                                ? {
                                                    ...selectedExtras[eg.id]
                                                      .extras,
                                                  }
                                                : {};

                                            const { [e.id]: _, ...newData } =
                                              oldSelectedExtras;

                                            if (eg.max !== undefined) {
                                              if (
                                                eg.max >
                                                Object.keys(newData).filter(
                                                  (k) => newData[k].checked
                                                ).length
                                              ) {
                                                setSelectedExtras({
                                                  ...selectedExtras,
                                                  [eg.id]: {
                                                    isRequired:
                                                      eg?.isRequired || false,
                                                    max: eg?.max,
                                                    extras: {
                                                      ...newData,
                                                      [event.target.name]: {
                                                        name: e.name,
                                                        stepperLabel: se.label,
                                                        category: eg.id,
                                                        order: e.order
                                                          ? e.order
                                                          : 0,
                                                        printOrder:
                                                          eg?.printOrder || 0,
                                                        price:
                                                          !eg.included &&
                                                          e.price !== undefined
                                                            ? delivery
                                                              ? eg.includedPriceDelivery
                                                                ? e.priceDelivery -
                                                                  eg.includedPriceDelivery
                                                                : e.priceDelivery
                                                              : eg.includedPrice
                                                              ? e.price -
                                                                eg.includedPrice
                                                              : e.price
                                                            : 0,
                                                        checked:
                                                          event.target.checked,
                                                      },
                                                    },
                                                  },
                                                });
                                              }
                                            } else {
                                              setSelectedExtras({
                                                ...selectedExtras,
                                                [eg.id]: {
                                                  isRequired:
                                                    eg?.isRequired || false,
                                                  max: eg?.max,
                                                  extras: {
                                                    ...newData,
                                                    [event.target.name]: {
                                                      name: e.name,
                                                      stepperLabel: se.label,
                                                      category: eg.id,
                                                      order: e.order
                                                        ? e.order
                                                        : 0,
                                                      printOrder:
                                                        eg?.printOrder || 0,
                                                      price:
                                                        !eg.included &&
                                                        e.price !== undefined
                                                          ? delivery
                                                            ? eg.includedPriceDelivery
                                                              ? e.priceDelivery -
                                                                eg.includedPriceDelivery
                                                              : e.priceDelivery
                                                            : eg.includedPrice
                                                            ? e.price -
                                                              eg.includedPrice
                                                            : e.price
                                                          : 0,
                                                      checked:
                                                        event.target.checked,
                                                    },
                                                  },
                                                },
                                              });
                                            }
                                          }}
                                          name={e.id}
                                        />
                                      }
                                    />
                                  ))}
                                </FormGroup>
                              )}
                            </FormControl>
                          </div>
                        ))}
                    </div>
                    <div>
                      <Button disabled={activeStep === 0} onClick={handleBack}>
                        Vorige
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                      >
                        {activeStep === steps.length - 1
                          ? "Gereed"
                          : "Volgende"}
                      </Button>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          )
        )
        //   activeStep === steps.length && (
        //     <Paper square elevation={0}>
        //       <Button
        //         style={{ marginRight: 8 }}
        //         className="focus:outline-none"
        //         variant="contained"
        //         size="large"
        //         startIcon={<CloseIcon />}
        //         onClick={handleReset}
        //       >
        //         Reset
        //       </Button>
        //       <Button
        //         variant="contained"
        //         className="font-bold items-center focus:outline-none"
        //         size="large"
        //         color="primary"
        //         startIcon={<CartIcon />}
        //       >
        //         Voeg toe
        //       </Button>
        //     </Paper>
        //   )}}
      }
    </div>
  );
};

export default ExtrasStepper;
