import { Box, Button, Container, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../firebase/firebase";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const history = useHistory();

  const handleForgotPassword = async () => {
    try {
      await auth.sendPasswordResetEmail(email);
      history.push("login");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container className="flex flex-col">
      <Box className="text-center">
        <h4 className="uppercase font-thin text-3xl">
          Wachtwoord opnieuw instellen
        </h4>
        <TextField
          className="m-3"
          variant="outlined"
          label="Email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></TextField>
        <div className="my-6">
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            onClick={handleForgotPassword}
          >
            Opnieuw instellen
          </Button>
        </div>
      </Box>
    </Container>
  );
};

export default PasswordReset;
