import React, { useState, createContext, useEffect, useContext } from "react";
import { useDispatch } from "react-redux";
import LoadingComponent from "../components/LoadingComponent";
import { auth } from "../firebase/firebase";
import { setLogin } from "../store/session/actions";

export const AuthStateContext = createContext({
  user: null,
  isLoading: false,
  getUser: null,
});

export const AuthContextProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getUser = () => {
    return auth.currentUser;
  };

  useEffect(() => {
    setIsLoading(true);
    auth.onAuthStateChanged((user) => {
      if (user) {
        console.log(user);
        setUser(user);
        setIsLoading(false);
        dispatch(setLogin(true));
      } else {
        setUser(null);
        setIsLoading(false);
        dispatch(setLogin(false));
      }
    });
  }, [dispatch]);

  return (
    <AuthStateContext.Provider
      value={{
        user: user,
        isLoading: isLoading,
        getUser: getUser,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
};

export const useAuthState = () => useContext(AuthStateContext);
