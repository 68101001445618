import React, { useEffect, useState } from "react";
import {
  Dialog,
  useMediaQuery,
  useTheme,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  IconButton,
  Divider,
} from "@material-ui/core";
import { db } from "../firebase/firebase";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addProduct } from "../store/cart/actions";

import MinusIcon from "react-feather/dist/icons/minus";
import PlusIcon from "react-feather/dist/icons/plus";
import CartIcon from "react-feather/dist/icons/shopping-cart";
import CloseIcon from "react-feather/dist/icons/x";
import ExtrasStepper from "./ExtrasStepper";
import ExtrasOptions from "./ExtrasOptions";

const ExtrasDialog = (props) => {
  const { open, setOpen, selectedProduct, addItem, delivery } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(false);
  const [extras, setExtras] = useState([]);
  const [selectedExtras, setSelectedExtras] = useState({});
  const [price, setPrice] = useState(selectedProduct && selectedProduct.price);
  const [qty, setQty] = useState(1);

  const handleClose = () => {
    setExtras([]);
    setSelectedExtras({});
    setQty(1);
    setOpen(false);
  };

  useEffect(() => {
    if (selectedProduct) {
      setPrice(selectedProduct.price);
      if (selectedProduct.extraGroups) {
        setLoading(true);
        const extraGroups = selectedProduct.extraGroups;

        db.collection("products")
          .where(
            "relatedTags",
            "array-contains-any",
            selectedProduct.extraGroups.map((ex) => ex.id)
          )
          .where("isEnabled", "==", true)
          .orderBy("extraOrder")
          .get()
          .then((qs) => {
            const extras = qs.docs.map((ds) => {
              return { ...ds.data(), id: ds.id };
            });

            const cats = extraGroups.map((ex) => {
              return {
                ...ex,
                extras: extras.filter((e) => e.relatedTags.includes(ex.id)),
              };
            });

            setExtras(cats);
            setLoading(false);
          });
      }
    }
  }, [delivery, selectedProduct]);

  useEffect(() => {
    if (selectedProduct) {
      const flatExtras = Object.keys(selectedExtras)
        .map((se) =>
          Object.keys(selectedExtras[se].extras).map(
            (e) => selectedExtras[se].extras[e]
          )
        )
        .flat()
        .filter((e) => e.checked);

      const extrasPrice = flatExtras.reduce((acc, curr) => {
        return acc + curr.price;
      }, 0);
      setPrice(selectedProduct.price + extrasPrice);
    }
  }, [delivery, qty, selectedExtras, selectedProduct]);

  const isButtonDisabled = () => {
    const requiredCategories = extras
      .filter((e) => e.isRequired)
      .map((fe) => fe.id);

    const foundCategories = Object.keys(selectedExtras)
      .map((se) =>
        Object.keys(selectedExtras[se].extras).map((e) => {
          return { id: e, ...selectedExtras[se].extras[e] };
        })
      )
      .flat()
      .filter((e) => e.checked)
      .map((ee) => ee.category);

    return !requiredCategories.every((v) => foundCategories.includes(v));
  };

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      fullScreen={fullScreen}
      open={open}
      onClose={() => {
        handleClose();
      }}
      className="justify-center items-center select-none"
    >
      <DialogTitle>
        <div className="w-full flex flex-row justify-between">
          <span className="flex flex-row items-center">
            <p className="font-bold">
              {selectedProduct && selectedProduct.name}
            </p>
            <p className="ml-2 font-hairline text-md">
              {selectedProduct && selectedProduct.size}
            </p>
          </span>

          <p className="text-xl font-bold" style={{ color: "#ffc107" }}>
            {selectedProduct &&
              ((price * qty) / 100).toFixed(2).replace(".", ",")}
            &euro;
          </p>
        </div>
      </DialogTitle>

      {loading ? (
        <div className="flex justify-center items-center">
          <CircularProgress size={80} disableShrink />
        </div>
      ) : (
        <DialogContent>
          <div className="flex flex-col py-4">
            <div className="mb-8">
              <p className="font-hairline uppercase text-sm">Aantal</p>
              <Divider />
            </div>
            <span className="flex flex-row items-center justify-between">
              <IconButton onClick={() => setQty(qty - 1)} disabled={qty === 1}>
                <MinusIcon />
              </IconButton>
              <p className="font-bold text-lg">{qty}</p>
              <IconButton onClick={() => setQty(qty + 1)}>
                <PlusIcon />
              </IconButton>
            </span>
          </div>
          {extras.length > 0 && (
            <div className="mb-8">
              <p className="font-hairline uppercase text-sm">Opties</p>
              <Divider />
            </div>
          )}
          {selectedProduct &&
            selectedProduct.extraGroups &&
            selectedProduct.extraGroups.length > 0 && (
              <ExtrasOptions
                extras={extras}
                delivery={delivery}
                selectedExtras={selectedExtras}
                setSelectedExtras={setSelectedExtras}
              />
            )}
          {selectedProduct &&
            selectedProduct.stepperExtras &&
            selectedProduct.stepperExtras.length > 0 && (
              <ExtrasStepper
                selectedProduct={selectedProduct}
                setSelectedExtras={setSelectedExtras}
                selectedExtras={selectedExtras}
                delivery={delivery}
                extras={extras}
                setExtras={setExtras}
              />
            )}
        </DialogContent>
      )}
      <DialogActions className="mb-8">
        <Button
          className="focus:outline-none"
          variant="contained"
          size="large"
          startIcon={<CloseIcon />}
          onClick={() => {
            handleClose();
          }}
        >
          Sluit
        </Button>
        <Button
          variant="contained"
          className="font-bold items-center focus:outline-none"
          size="large"
          color="primary"
          disabled={isButtonDisabled()}
          onClick={() => {
            const extrs = Object.keys(selectedExtras)
              .map((se) =>
                Object.keys(selectedExtras[se].extras).map((e) => {
                  return { id: e, ...selectedExtras[se].extras[e] };
                })
              )
              .flat()
              .filter((e) => e.checked)
              .sort((a, b) => {
                if (a.printOrder < b.printOrder) {
                  return -1;
                }
                if (a.printOrder > b.printOrder) {
                  return 1;
                }

                if (a.order < b.order) {
                  return -1;
                }
                if (a.order > b.order) {
                  return 1;
                }

                return 0;
              });

            addItem({
              id: selectedProduct.id,
              name: selectedProduct.name,
              price: price,
              order: selectedProduct.order,
              categoryOrder: selectedProduct.categoryOrder,
              size: selectedProduct.size,
              vat: selectedProduct.vat ? selectedProduct.vat : 6,
              qty: qty,
              extras: extrs,
            });
            handleClose();
          }}
          // style={{ fontWeight: "bold", fontSize: 16 }}
          startIcon={<CartIcon />}
        >
          Voeg toe
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToPtops = (state) => ({ ...state.order });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addItem: addProduct,
    },
    dispatch
  );

export default connect(mapStateToPtops, mapDispatchToProps)(ExtrasDialog);
