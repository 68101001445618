export const ADD_PRODUCT = "ADD_PRODUCT";
export const CLEAR_CART = "CLEAR_CART";
export const REMOVE_PRODUCT = "REMOVE_PRODUCT";
export const SET_COUPON = "SET_COUPON";
export const CLEAR_COUPON = "CLEAR_COUPON";
export const SET_OPEN = "SET_OPEN";

export const addProduct = (product) => {
  return {
    type: ADD_PRODUCT,
    payload: product,
  };
};

export const clearCart = () => {
  return {
    type: CLEAR_CART,
  };
};

export const removeProduct = (id) => {
  return {
    type: REMOVE_PRODUCT,
    payload: id,
  };
};

export const setCoupon = (coupon) => {
  return {
    type: SET_COUPON,
    payload: coupon,
  };
};

export const clearCoupon = () => {
  return {
    type: CLEAR_COUPON,
  };
};

export const setOpen = (isOpen) => {
  return {
    type: SET_OPEN,
    payload: isOpen,
  };
};
