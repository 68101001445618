import {
  SET_DELIVERY,
  SET_FAILED_ORDER,
  SET_SUCCESS_ORDER,
  REMOVE_ERROR,
} from "./actions";

const initialState = {
  delivery: false,
  failedOrder: null,
  paymentError: null,
};

export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DELIVERY: {
      const isDelivery = action.payload;

      return {
        ...state,
        delivery: isDelivery,
      };
    }
    case SET_SUCCESS_ORDER: {
      return {
        ...state,
        failedOrder: null,
        paymentError: null,
      };
    }
    case SET_FAILED_ORDER: {
      const { order, error } = action.payload;

      return {
        ...state,
        failedOrder: order,
        paymentError: error,
      };
    }
    case REMOVE_ERROR: {
      return {
        ...state,
        paymentError: null,
      };
    }
    default:
      return state;
  }
};
