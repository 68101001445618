import hash from "object-hash";
import {
  ADD_PRODUCT,
  CLEAR_CART,
  REMOVE_PRODUCT,
  SET_COUPON,
  SET_OPEN,
  CLEAR_COUPON,
} from "./actions";

const initialState = {
  items: [],
  coupon: null,
  totalPrice: 0,
  totalItems: 0,
  isOpen: true,
};

export const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PRODUCT: {
      const item = action.payload;
      const { items } = state;
      let newItems = items;

      const hashOfItem = hash({
        id: item.id,
        name: item.name,
        size: item.size,
        extras: item.extras,
      });

      const indexFound = items.findIndex((it) => it.id === hashOfItem);

      if (indexFound > -1) {
        newItems[indexFound].qty += item.qty;
      } else {
        newItems = [...newItems, { ...item, id: hashOfItem }];
      }

      return {
        ...state,
        totalPrice: newItems.reduce(
          (acc, current) => acc + current.price * current.qty,
          0
        ),
        totalItems: newItems.reduce((acc, current) => acc + current.qty, 0),
        items: newItems.sort((a, b) => {
          if (a.categoryOrder < b.categoryOrder) {
            return -1;
          }
          if (a.categoryOrder > b.categoryOrder) {
            return 1;
          }

          if (a.order < b.order) {
            return -1;
          }
          if (a.order > b.order) {
            return 1;
          }

          return 0;
        }),
      };
    }
    case CLEAR_CART: {
      return initialState;
    }
    case REMOVE_PRODUCT: {
      const id = action.payload;
      const { items } = state;
      const newItems = items.filter((it) => it.id !== id);
      return {
        ...state,
        totalPrice: newItems.reduce(
          (acc, current) => acc + current.price * current.qty,
          0
        ),
        totalItems: newItems.reduce((acc, current) => acc + current.qty, 0),
        items: newItems,
      };
    }
    case SET_COUPON: {
      const coupon = action.payload;

      return {
        ...state,
        coupon: coupon,
      };
    }
    case CLEAR_COUPON: {
      return {
        ...state,
        coupon: null,
      };
    }
    case SET_OPEN: {
      const isOpen = action.payload;

      return {
        ...state,
        isOpen: isOpen,
      };
    }
    default:
      return state;
  }
};
