import React, { useEffect, useState } from "react";
import cover from "../assets/img/cover.jpg";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { setDelivery } from "../store/order/actions";
import { connect } from "react-redux";
import { clearCart } from "../store/cart/actions";
import ZoneDialog from "./ZoneDialog";
import Alert from "@material-ui/lab/Alert";
import { db } from "../firebase/firebase";
import parse from "html-react-parser";
import PromoDialog from "./PromoDialog";

const Home = (props) => {
  const { setDeliver, delivery, clear } = props;
  const [openZoneSelector, setOpenZoneSelector] = useState(false);

  // const sessionKey = sessionStorage.getItem("closePopup") === 0 ? false : true;

  const [openSluitingDialog, setOpenSluitingDialog] = useState(false);
  const [messages, setMessages] = useState([]);

  const history = useHistory();

  useEffect(() => {
    db.collection("messages")
      .get()
      .then((qs) => setMessages(qs.docs.map((ds) => ds.data())));
  }, []);

  useEffect(() => {
    clear();
  }, [clear, delivery]);

  return (
    <div>
      {messages.map((m) => (
        <Alert
          variant="filled"
          elevation={6}
          severity="warning"
          className="my-4">
          <p>Beste klanten, </p>
          <br></br>
          <p>{parse(m.message)}</p>
          <br></br>
          <p>Groetjes,</p>
          <p>Team Den Baba</p>
        </Alert>
      ))}
      <PromoDialog open={openSluitingDialog} setOpen={setOpenSluitingDialog} />
      <ZoneDialog open={openZoneSelector} />
      <div
        className="bg-fixed w-full"
        style={{
          minHeight: 400,
          backgroundImage: `url(${cover})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      />
      <div className="p-4 select-none">
        <p className="text-3xl font-bold text-center">Welkom bij Den BABA</p>
        <p className="text-center">
          Wij leveren in de volgende gemeentes: <br />
          <br />
          <span className="font-bold">Aalst</span>,{" "}
          <span className="font-bold">Bambrugge</span>,{" "}
          <span className="font-bold">Erondegem</span>,{" "}
          <span className="font-bold">Erpe Mere</span>,{" "}
          <span className="font-bold">Hofstade</span>,{" "}
          <span className="font-bold">Ottergem</span> en{" "}
          <span className="font-bold">Vlekkem</span> <br /> voor een minimum
          bestelbedrag van &euro; 15,00 .
          <br />
          <br />
          <span className="font-bold">Burst</span>,{" "}
          <span className="font-bold">Erembodegem</span>,{" "}
          <span className="font-bold">Haaltert</span>,{" "}
          <span className="font-bold">Lede</span>,{" "}
          <span className="font-bold">Nieuwerkerken</span> voor een minimum
          bestelbedrag van &euro; 20,00
          <br />
          <br />
          <span className="font-bold">Aaigem</span>,{" "}
          <span className="font-bold">Kerksken</span> voor een minimum
          bestelbedrag van &euro; 25,00
        </p>
        <div className="flex flex-row justify-center mt-10">
          <button
            className="btn border py-2 px-6 font-bold uppercase text-xl"
            onClick={() => {
              setDeliver(false);
              history.push("/catalog");
            }}>
            Afhalen
          </button>
          <button
            className="btn border py-2 px-6 font-bold uppercase text-xl"
            onClick={() => {
              setDeliver(true);
              setOpenZoneSelector(true);
              // history.push('/catalog');
            }}>
            Leveren
          </button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.order,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDeliver: setDelivery,
      clear: clearCart,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Home);
