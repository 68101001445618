import React from "react";
import ReactDOM from "react-dom";
import "./assets/main.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// import LogRocket from "logrocket";

import { Provider } from "react-redux";

import configureStore from "./store";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core";
import { amber, red } from "@material-ui/core/colors";
import { AuthContextProvider } from "./contexts/AuthContextProvider";

// LogRocket.init("zy9bfw/denbaba");

const store = configureStore();

const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: amber,
    secondary: red,
    divider: "rgba(255, 255, 255, 0.12)",
    background: {
      paper: "#424242",
      default: "#303030",
    },
  },
  typography: {
    h1: {
      fontWeight: "500",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthContextProvider>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </AuthContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
