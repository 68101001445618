import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Catalog from "./components/Catalog";
import Home from "./components/Home";
import Promo from "./components/Promo";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Confirm from "./components/Confirm";
import Confirmation from "./components/Confirmation";
// import Promo from "./components/Promo";
import { setZones } from "./store/session/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TermsOfUse from "./components/TermsOfUse";

import axios from "axios";
import { setOpen } from "./store/cart/actions";
import { getZones } from "./firebase/db/zones";
import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile";
import PasswordReset from "./components/PasswordReset";
import ProtectedRoute from "./components/ProtectedRoute";

const App = (props) => {
  const { setIsOpen, setDeliveryZones } = props;

  useEffect(() => {
    axios
      .get("https://europe-west1-denbaba-59dbd.cloudfunctions.net/isOpen")
      .then((response) => {
        const { open } = response.data;

        setIsOpen(open);
      })
      .catch((err) => console.log(err));
  }, [setIsOpen]);

  useEffect(() => {
    getZones().then((zones) => setDeliveryZones(zones));
  }, [setDeliveryZones]);

  return (
    <>
      <Header />
      <div className="flex flex-col flex-grow">
        <Router>
          <Switch>
            <Route path="/catalog">
              <Catalog />
            </Route>

            <Route path="/confirm">
              <Confirm />
            </Route>

            <Route path="/login">
              <Login />
            </Route>

            <Route path="/register">
              <Register />
            </Route>

            <Route path="/reset-password">
              <PasswordReset />
            </Route>

            <Route path={"/profile"}>
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            </Route>

            <Route exact path="/confirmation/:id" component={Confirmation} />
            <Route exact path="/termsofuse" component={TermsOfUse} />
            <Route path="">
              {/* <Promo /> */}
              <Home />
            </Route>
          </Switch>
        </Router>
      </div>
      <Footer />
    </>
  );
};

// const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setIsOpen: setOpen,
      setDeliveryZones: setZones,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(App);
