import { Box, Button, Container, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../firebase/firebase";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const handleRegister = async () => {
    try {
      await auth.createUserWithEmailAndPassword(email, password);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container className="flex flex-col">
      <Box className="text-center">
        <h4 className="uppercase font-thin text-3xl">Registreren</h4>
        <TextField
          className="m-3"
          variant="outlined"
          label="Voornaam"
          fullWidth
          required
          margin="normal"
        ></TextField>
        <TextField
          className="m-3"
          variant="outlined"
          label="Achternaam"
          fullWidth
          required
          margin="normal"
        ></TextField>
        <TextField
          className="m-3"
          variant="outlined"
          label="Email"
          fullWidth
          required
          margin="normal"
        ></TextField>
        <TextField
          variant="outlined"
          label="Wachtwoord"
          fullWidth
          type="password"
          margin="normal"
          required
          autoComplete="new-password"
        ></TextField>
        <TextField
          variant="outlined"
          label="Herhaal wachtwoord"
          fullWidth
          type="password"
          margin="normal"
          required
          autoComplete="new-password"
        ></TextField>
        <div className="my-6">
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            onClick={handleRegister}
          >
            Registreren
          </Button>
        </div>
        <div className="flex justify-end">
          <Button size="small" onClick={() => history.push("login")}>
            Bestaande klant
          </Button>
        </div>
      </Box>
    </Container>
  );
};

export default Register;
