import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";

const Footer = () => {
  return (
    <div className="border-t border-gray-600 p-4 ">
      <div className="flex flex-row justify-between items-center flex-wrap gap-4">
        <div>
          <p className="text-2xl font-black mb-4">Den Baba</p>
          <p>Gentsesteenweg 129</p>
          <p>9300, Aalst</p>
          <br />
          <span itemProp="telephone">
            <a href="tel:+3253400050" rel="nofollow">
              053 40 00 50
            </a>
          </span>
          <br />
          <span itemProp="email">
            <a href="mailto:info@denbaba.be" rel="nofollow">
              info@denbaba.be
            </a>
          </span>
          <br />
          <p>BE 0726.981.643</p>
          <div className="mt-4">
            <a href="https://www.facebook.com/DENBABAMERE/">
              <FacebookIcon fontSize="large" />
            </a>
            <a href="https://www.instagram.com/denbabamere/">
              <InstagramIcon fontSize="large" />
            </a>
          </div>
        </div>
        <div className="py-4 px-2 flex-1">
          <div className="flex flex-row justify-between gap-16">
            <p className="ml-auto">Ma</p>
            <p className="font-bold">Gesloten</p>
          </div>
          <div className="flex flex-row justify-between  gap-16">
            <p className="ml-auto">Di-Do</p>
            <p className="font-bold">17h - 21h</p>
          </div>
          <div className="flex flex-row justify-between  gap-16">
            <p className="ml-auto">Vri-Za</p>
            <p className="font-bold">17h - 22h</p>
          </div>
          <div className="flex flex-row justify-between  gap-16">
            <p className="ml-auto">Zo</p>
            <p className="font-bold">17h - 21h</p>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-around font-hairline">
        <a href="/termsofuse">Algemene voorwaarden</a>
        {/* <a href="/">Privacy policy</a> */}
      </div>
      <p className="text-center font-hairline">&copy; 2022 - Den Baba</p>
    </div>
  );
};

export default Footer;
