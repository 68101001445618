import { Box, Button, Container, TextField } from "@material-ui/core";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { auth } from "../firebase/firebase";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory();

  const handleLogin = async () => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => console.log("Logged in"))
      .catch((err) => console.log(err));

    // try {

    //   await auth.signInWithEmailAndPassword(email, password);
    //   return history.push("profile");
    // } catch (err) {
    //   console.log(err);
    // }
  };

  return (
    <Container className="flex flex-col">
      <Box className="text-center" noValidate autoComplete="off">
        <h4 className="uppercase font-thin text-3xl">Aanmelden</h4>
        <TextField
          className="m-3"
          variant="outlined"
          label="Email"
          fullWidth
          margin="normal"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        ></TextField>
        <TextField
          variant="outlined"
          label="Wachtwoord"
          fullWidth
          type="password"
          margin="normal"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        ></TextField>
        <div className="my-6">
          <Button
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            onClick={handleLogin}
          >
            Aanmelden
          </Button>
        </div>
        <div className="flex justify-between">
          <Button size="small" onClick={() => history.push("register")}>
            Nieuwe klant
          </Button>
          <Button size="small" onClick={() => history.push("reset-password")}>
            Wachtwoord vergeten
          </Button>
        </div>
      </Box>
    </Container>
  );
};

export default Login;
