import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setSelectedZone } from "../store/session/actions";

const ZoneDialog = (props) => {
  const history = useHistory();

  const { open, zones = [], setZone, selectedZone } = props;
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle style={{ color: "#ffc107" }}>Kies uw gemeente</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          name="zone"
          onChange={(e) => {
            const selectedZon = zones.find((z) => z.id === e.target.value);
            if (selectedZon) {
              setZone(selectedZon);
            }
          }}
        >
          {zones.length > 0 &&
            zones.map((zone) => (
              <FormControlLabel
                className="mb-4"
                value={zone.id}
                key={zone.id}
                control={
                  <Radio
                    color="primary"
                    checked={selectedZone && selectedZone.id === zone.id}
                  />
                }
                label={
                  <span className="bg-yellow-400">
                    <p className="text-md font-bold">{zone.name}</p>
                    <p className="text-sm font-hairline text-gray-200">
                      v.a. {(zone.minAmount / 100).toFixed(2).replace(".", ",")}
                      &euro;
                    </p>
                  </span>
                }
              />
            ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={selectedZone === null || selectedZone === undefined}
          onClick={() => history.push("/catalog")}
        >
          Bevestigen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  ...state.session,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setZone: setSelectedZone,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ZoneDialog);
