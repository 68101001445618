import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import throttle from "lodash/throttle";
import { loadState, saveState } from "./localStorage";
import { cartReducer } from "./cart/reducers";
import { sessionReducer } from "./session/reducers";
import { orderReducer } from "./order/reducers";

const rootReducer = combineReducers({
  cart: cartReducer,
  session: sessionReducer,
  order: orderReducer,
});

const persistedState = loadState();

const configureStore = () => {
  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    persistedState,
    composeWithDevTools(middlewareEnhancer)
  );

  store.subscribe(
    throttle(() => {
      saveState(store.getState());
    }),
    1000
  );

  return store;
};

export default configureStore;
