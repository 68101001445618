import React, { useEffect, useState } from "react";
import { db, analytics } from "../firebase/firebase";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import CartContent from "./CartContent";
import { setSuccessOrder, setFailedOrder } from "../store/order/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { clearCart, setCoupon, clearCoupon } from "../store/cart/actions";

const Confirmation = (props) => {
  const {
    match: {
      params: { id },
    },
    setFailed,
    setSuccess,
    setDiscount,
    clearDiscount,
    clear,
  } = props;

  const [order, setOrder] = useState({});

  const history = useHistory();

  // FETCH ORDER WITH STATUS
  useEffect(() => {
    if (id) {
      const unsubscribe = db
        .collection("orders")
        .doc(id)
        .onSnapshot((doc) => {
          if (doc.exists) {
            setOrder(doc.data());
          } else {
            history.push("/confirm");
          }
        });

      return () => unsubscribe();
    }
  }, [history, id]);

  useEffect(() => {
    if (order) {
      if (order.status === "new") {
        setDiscount(null);
        clear();
        clearDiscount();
        // analytics.logEvent("purchase", {
        //   currency: "EUR",
        //   value: order.totalPrice / 100,
        // });
        setSuccess();
      } else if (order.status === "canceled") {
        setFailed({
          order: { ...order, id },
          error:
            "Online betaling is niet gelukt. Probeer opnieuw of kies een andere betaalmethode",
        });
        history.push("/confirm");
      }
    }
  }, [
    clear,
    clearDiscount,
    history,
    id,
    order,
    setDiscount,
    setFailed,
    setSuccess,
  ]);

  return (
    <div className="flex flex-col flex-grow justify-center bg-red-white p-4">
      {order && order.status === "pending" && (
        <div className="flex flex-col items-center p-4">
          <p className="font-bold text-2xl">Verwerken</p>
          <p className="p-4 text-center">
            Wij verwerken momenteel uw betaling.
            <br /> Deze pagina zal automatisch vernieuwen als de betaling gelukt
            is.
          </p>
          <CircularProgress color="primary" className="mt-4" />
        </div>
      )}
      {order && (order.status === "new" || order.status === "confirmed") && (
        <div className="flex flex-col">
          <p className="text-center text-3xl font-bold mb-8 uppercase">
            Bedankt voor uw bestelling
          </p>
          <p className="text-center mb-8">
            Merkt u een fout op in uw bestelling of in uw gegevens? <br />{" "}
            Gelieve dan zo snel mogelijk contact op te nemen met ons op het
            nummer{" "}
            <a className="font-bold" href="tel:+3253400050">
              053 40 00 50
            </a>
          </p>
          {!order.delivery ? (
            <p className="text-center">
              U kan uw bestelling gaan AFHALEN om{" "}
              <span className="text-xl font-bold">{order.time}</span>
            </p>
          ) : (
            <p className="text-center">
              Uw bestelling zal geleverd worden rond{" "}
              <span className="text-xl font-bold">
                {order.time === "asap" ? (
                  order.status === "confirmed" ? (
                    <p>Zo snel mogelijk (circa {`${order.etd})`}</p>
                  ) : (
                    "Zo snel mogelijk"
                  )
                ) : (
                  order.time
                )}
              </span>
              Wij doen ons uiterste best om uw bestelling zo snel mogelijk te
              bezorgen, bezorgtijden kunnen afwijken naargelang de drukte.
            </p>
          )}
          <p className="text-2xl font-thin uppercase text-gray-400 mb-4">
            Bestelling
          </p>
          <CartContent orderItems={order.items} editable={false} />
          {order.coupon && (
            <>
              <span className="flex flex-row justify-between items-center">
                <p className="flex-grow text-right mr-2 font-hairline">
                  Subtotaal
                </p>
                <p className="font-bold text-2xl text-right">
                  {((order.totalPrice + order.coupon.discount) / 100)
                    .toFixed(2)
                    .replace(".", ",")}{" "}
                  &euro;
                </p>
              </span>
              <span className="flex flex-row justify-between items-center">
                <p className="flex-grow text-right mr-2 font-hairline">
                  {`Coupon ${order.coupon.label}`}
                </p>
                <p className="font-bold text-2xl text-right text-red-600">
                  {(0 - order.coupon.discount / 100)
                    .toFixed(2)
                    .replace(".", ",")}{" "}
                  &euro;
                </p>
              </span>

              <hr />
            </>
          )}

          <span className="flex flex-row justify-between items-center">
            <p className="flex-grow text-right mr-2 font-hairline">Totaal</p>
            <p className="font-bold text-2xl text-right">
              {(order.totalPrice / 100).toFixed(2).replace(".", ",")} &euro;
            </p>
          </span>
          {order.payment === "online" && order.onlinePayment.status === "paid" && (
            <span>
              <p className="text-right font-bold">Online betaald</p>
            </span>
          )}
          {order.comment && (
            <div className="my-4">
              <p className="text-2xl font-thin uppercase text-gray-400 mb-4">
                Opmerkingen
              </p>
              <p>{order.comment}</p>
            </div>
          )}
          <p className="text-2xl font-thin uppercase text-gray-400 mb-4">
            Gegevens
          </p>

          <p>{`${order.firstName} ${order.lastName}`}</p>
          {order.delivery && (
            <div className="my-2">
              <p>{`${order.street} ${order.number} ${
                order.bus ? order.bus : ""
              }`}</p>
              <p>{`${order.postalCode} ${order.city}`}</p>
            </div>
          )}
          <p>{order.email}</p>
          <p>{order.telephone}</p>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setFailed: setFailedOrder,
      setSuccess: setSuccessOrder,
      clear: clearCart,
      setDiscount: setCoupon,
      clearDiscount: clearCoupon,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Confirmation);
