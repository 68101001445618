import React, { useEffect, useState } from "react";
import { db } from "../firebase/firebase";
import { CircularProgress, Tabs, Tab, Paper } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, Element } from "react-scroll";

import { addProduct } from "../store/cart/actions";
import ExtrasDialog from "./ExtrasDialog";
import CartDialog from "./CartDialog";
import { amber } from "@material-ui/core/colors";

const LinkTab = (props) => {
  return (
    <Tab
      component={Link}
      onClick={(event) => event.preventDefault()}
      {...props}
    />
  );
};

const Catalog = (props) => {
  const { totalPrice, totalItems, isOpen, delivery } = props;

  const [catalog, setCatalog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openExtrasModal, setOpenExtrasModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [openCartModal, setOpenCartModal] = useState(false);

  useEffect(() => {
    db.collection("products")
      .where("isEnabled", "==", true)
      .where("inCatalog", "==", true)
      .orderBy("categoryOrder")
      .orderBy("order")
      .get()
      .then((qs) => {
        const products = qs.docs.map((ds) => {
          return { ...ds.data(), id: ds.id };
        });
        const cats = products.reduce((acc, current) => {
          if (current.category) {
            if (!acc[current.category]) {
              acc[current.category] = [current];
            } else {
              acc[current.category] = [...acc[current.category], current];
            }
          }
          return acc;
        }, {});
        const cat = Object.keys(cats).map((c) => ({
          label: c,
          products: cats[c],
        }));
        setCatalog(cat);
        setSelectedCategory(0);
        setLoading(false);
      });
  }, []);

  return loading ? (
    <div className="flex-grow flex justify-center items-center">
      <CircularProgress size={80} disableShrink />
    </div>
  ) : (
    <div>
      <ExtrasDialog
        open={openExtrasModal}
        setOpen={setOpenExtrasModal}
        selectedProduct={selectedProduct}
      />
      <div className="sticky top-0 bg-black">
        <Tabs
          value={selectedCategory}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          onChange={(idx, value) => setSelectedCategory(value)}>
          {catalog.map((c, idx) => (
            <LinkTab
              key={c.label}
              to={c.label}
              spy={true}
              hashSpy={true}
              label={c.label}
              duration={500}
              offset={-50}
              ignoreCancelEvents={true}
              onSetActive={(ev) => {
                setSelectedCategory(idx);
              }}
            />
          ))}
        </Tabs>
      </div>
      {catalog.map((c, idx) => (
        <Element id={c.label} key={c.label} className="p-4 element">
          <p className="text-xl p-4 font-bold text-yellow-600">{c.label}</p>
          <hr />
          {c.products.map((p) => (
            <div key={p.id}>
              <div
                className="flex flex-row justify-between items-center p-2"
                key={p.id}>
                <span className="flex flex-row items-center select-none">
                  {p.new && (
                    <span
                      className="p-2 rounded-full font-bold mr-2 text-sm"
                      style={{ backgroundColor: amber[500] }}>
                      NEW
                    </span>
                  )}
                  <span className="flex flex-col">
                    <p className="flex-shrink">{p.name}</p>
                    {p.description && (
                      <p className="font-hairline text-sm">{p.description}</p>
                    )}
                  </span>
                </span>

                {p.sizes ? (
                  <div className="flex flex-row justify-between items-center flex-srhink-0">
                    {p.sizes
                      .filter((s) => s.isEnabled)
                      .map((s) => (
                        <div className="flex flex-col ml-4" key={s.name}>
                          <p className="text-center text-sm">{s.name}</p>
                          <button
                            className="border py-2 px-4 rounded font-bold text-right focus:outline-none"
                            onClick={() => {
                              // analytics.logEvent("view_item", {
                              //   currency: "EUR",
                              //   value: delivery ? s.priceDelivery : s.price,
                              //   items: [
                              //     {
                              //       item_id: p.id,
                              //       item_name: p.name,
                              //       item_variant: s.name,
                              //     },
                              //   ],
                              // });
                              setSelectedProduct({
                                ...p,
                                id: `${p.id}${s.name
                                  .replace(/\s/g, "")
                                  .toLowerCase()}`,
                                price: delivery ? s.priceDelivery : s.price,
                                size: s.name,
                              });
                              setOpenExtrasModal(true);
                            }}>
                            {((delivery ? s.priceDelivery : s.price) / 100)
                              .toFixed(2)
                              .replace(".", ",")}
                            &euro;
                          </button>
                        </div>
                      ))}
                  </div>
                ) : (
                  <button
                    className="border py-2 px-4 rounded font-bold text-right focus:outline-none"
                    onClick={() => {
                      // analytics.logEvent("view_item", {
                      //   currency: "EUR",
                      //   value: delivery ? p.priceDelivery : p.price,
                      //   items: [
                      //     {
                      //       item_id: p.id,
                      //       item_name: p.name,
                      //     },
                      //   ],
                      // });
                      setSelectedProduct({
                        ...p,
                        size: null,
                        price: delivery ? p.priceDelivery : p.price,
                      });
                      setOpenExtrasModal(true);
                    }}>
                    {((delivery ? p.priceDelivery : p.price) / 100)
                      .toFixed(2)
                      .replace(".", ",")}
                    &euro;
                  </button>
                )}
              </div>
            </div>
          ))}
        </Element>
      ))}
      <CartDialog
        openCartModal={openCartModal}
        setOpenCartModal={setOpenCartModal}
      />
      <div
        className={`sticky bottom-0 w-full select-none focus:outline-none ${
          !isOpen || totalItems < 1 ? "cursor-not-allowed" : "cursor-pointer"
        }`}
        disabled={totalItems < 1 || !isOpen}
        onClick={() => {
          if (totalItems > 0 && isOpen) setOpenCartModal(true);
        }}>
        <Paper
          elevation={10}
          square
          className="p-8"
          color={isOpen ? "primary" : "secondary"}>
          <div className="flex justify-between">
            {isOpen ? (
              <>
                <span className="flex flex-row">
                  <p className="mr-2 font-bold">
                    {totalItems > 0 ? totalItems : ""}
                  </p>
                  <p className="font-thin">
                    {totalItems > 0
                      ? `product${totalItems > 1 ? "en" : ""}`
                      : "Geen producten"}
                  </p>
                </span>

                <p className="font-bold">
                  {(totalPrice / 100).toFixed(2).replace(".", ",")} &euro;
                </p>
              </>
            ) : (
              <p className="uppercase text-2xl text-center">
                Momenteel gesloten
              </p>
            )}
          </div>
        </Paper>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state.cart,
  ...state.order,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      addItem: addProduct,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Catalog);
