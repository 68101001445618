import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Toolbar,
} from "@material-ui/core";
import React from "react";
import logo from "../assets/img/logo.png";
import { useAuthState } from "../contexts/AuthContextProvider";

// import AlertCircle from "react-feather/dist/icons/alert-circle";

const Header = () => {
  const { user, isLoading } = useAuthState();

  return (
    <>
      {/* <div className="bg-red-600 p-4 flex flex-row items-center">
        <AlertCircle className="mr-4" />
        <div className="flex flex-col">
          <p className="font-bold text-lg">
            COVID-19
            <br />
          </p>
          <p>
            Beste klanten,
            <br /> Gelieve te letten op volgende regels bij het afhalen van uw
            bestelling: <br />
          </p>
          <ul>
            <li>Houd steeds minstens 1,5m afstand van elkaar.</li>
            <li>
              Het is momenteel enkel maar mogelijk om online te betalen op de
              website.
            </li>
          </ul>
        </div>
      </div> */}

      {/* <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters className="py-8">
            <a
              href="/"
              className="flex justify-center"
              style={{ height: "20vmin" }}
            >
              <img src={logo} style={{ objectFit: "contain" }} alt="logo" />
            </a>

            <Box
              sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            ></Box>
          </Toolbar>
        </Container>
      </AppBar> */}

      <div className="flex justify-center items-center py-8">
        <a
          href="/"
          className="flex justify-center"
          style={{ height: "20vmin" }}
        >
          <img src={logo} style={{ objectFit: "contain" }} alt="logo" />
        </a>
        {/* <Button variant="contained" color="primary" load>
          {isLoading ? (
            <CircularProgress color="dark" size={30} />
          ) : user ? (
            user.email
          ) : (
            "Aanmelden"
          )}
        </Button> */}
      </div>
    </>
  );
};

export default Header;
