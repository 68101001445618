export const SET_LOGIN = "SET_LOGIN";
export const SET_SELECTED_ZONE = "SET_SELECTED_ZONE";
export const SET_ZONES = "SET_ZONES";

export const setLogin = (isAuth) => {
  return {
    type: SET_LOGIN,
    payload: isAuth,
  };
};

export const setSelectedZone = (zone) => {
  return {
    type: SET_SELECTED_ZONE,
    payload: zone,
  };
};

export const setZones = (zones) => {
  return {
    type: SET_ZONES,
    payload: zones,
  };
};
