import React from "react";

const TermsOfUse = () => {
  return (
    <div className="p-4 font-hairline">
      <h1 className="text-lg  font-bold">ALGEMENE VOORWAARDEN</h1>
      <br></br>
      <p>Den Baba gelegen te Aalst op Gentsesteenweg 129.</p>
      <br></br>
      <h2 className="font-bold">1. Algemeen / Toepasselijkheid</h2>
      <br></br>
      <p className="ml-2">
        1.1 Deze Algemene Voorwaarden zijn van toepassing op alle aanbiedingen
        van Den Baba en op alle overeenkomsten tussen Den Baba en Koper.
      </p>
      <p className="ml-2">
        1.2 Onder " Den Baba " wordt in deze algemene voorwaarden en elders op
        de site verstaan als Den Baba met de vestiging gelegen te Gentsesteenweg
        129, 9300 Aalst.
      </p>
      <p className="ml-2">
        1.3 Onder "Koper" wordt verstaan iedere bezoeker van de internetsite
        en/of elke natuurlijke persoon of rechtspersoon die goederen via de
        internetsite denbaba.be koopt bij Den Baba.
      </p>
      <p className="ml-2">
        1.4 Den Baba behoudt zich het recht voor deze voorwaarden van tijd tot
        tijd te wijzigen of aan te vullen. De gewijzigde voorwaarden zijn dan
        ook van toepassing op de reeds gesloten overeenkomsten, tenzij zulks in
        een concrete situatie in strijd met de redelijkheid en billijkheid zou
        zijn.
      </p>
      <p className="ml-2">
        1.5 Door het gebruik van de internetsite van Den Baba en/of het plaatsen
        van een bestelling aanvaardt de Koper deze Algemene Voorwaarden alsmede
        alle andere rechten en plichten zoals die staan vermeld op de
        internetsite.
      </p>
      <p className="ml-2">
        1.6 Naast deze Algemene Voorwaarden kunnen -indien nadrukkelijk
        aangegeven- tevens Aanvullende Voorwaarden van toepassing zijn op
        bepaalde diensten en/of producten. Mochten er tussen bepalingen in de
        Aanvullende Voorwaarden en bepalingen in deze Algemene Voorwaarden
        verschillen bestaan dan gelden, in beginsel, de betreffende bepalingen
        uit de Aanvullende Voorwaarden boven die uit de Algemene Voorwaarden,
        tenzij anders is bepaald.
      </p>
      <p className="ml-2">
        1.7 Van het in deze Algemene Voorwaarden bepaalde kan uitsluitend worden
        afgeweken indien dit uitdrukkelijk schriftelijk is overeengekomen.
      </p>
      <p className="ml-2">
        1.8 Indien ook de Koper naar zijn algemene voorwaarden verwijst, zijn
        die voorwaarden niet van toepassing, tenzij hiermee uitdrukkelijk
        schriftelijk door Den Baba is ingestemd.
      </p>
      <p className="ml-2">
        1.9 Indien bij een gerechtelijke uitspraak een of meer artikelen van
        deze voorwaarden ongeldig worden verklaard, zullen de overige bepalingen
        van deze voorwaarden volledig van kracht blijven en zullen Den Baba en
        Koper in overleg treden teneinde nieuwe bepalingen ter vervanging van de
        nietige, dan wel vernietigde, bepalingen overeen te komen, waarbij
        zoveel mogelijk het doel en de strekking van de nietige, dan wel
        vernietigde, bepalingen in acht worden genomen.
      </p>
      <p className="ml-2">
        1.10 Den Baba is bevoegd van derden gebruik te maken bij de uitvoering
        van een overeenkomst met Koper.
      </p>
      <br></br>
      <h2 className="font-bold">
        2. Aanbiedingen en totstandkoming overeenkomsten
      </h2>
      <br></br>
      <p className="ml-2">
        2.1 Alle aanbiedingen van Den Baba zijn vrijblijvend. Aanbiedingen of
        prijsopgaven dienen te worden aangemerkt als een uitnodiging aan de
        potentiële Koper tot het doen van een aanbod. Den Baba is op geen enkele
        wijze hieraan gebonden, tenzij dit schriftelijk en ondubbelzinnig is
        vastgelegd. De aanvaarding van de uitnodiging tot het doen van een
        aanbod door de potentiële Koper geldt als een aanbod en leidt slechts
        tot een totstandkoming van een overeenkomst als aan het overige bepaalde
        in dit artikel is voldaan. Er is voor Den Baba nimmer een verplichting
        om met een potentiële Koper een overeenkomst te sluiten.
      </p>
      <p className="ml-2">
        2.2 Aanbiedingen en overeenkomsten geschieden onder voorbehoud van de
        beschikbaarheid van producten (zolang de voorraad strekt). Indien een
        product dat tijdelijk niet op voorraad is door de Koper wordt of is
        besteld, zal waar mogelijk worden aangegeven wanneer het product weer
        beschikbaar is.
      </p>
      <p className="ml-2">
        {" "}
        2.3 Een overeenkomst komt tot stand op het moment dat een
        orderbevestiging aan de Koper per e-mail is verzonden naar het door
        Koper opgegeven e-mailadres.
      </p>
      <p className="ml-2">
        {" "}
        2.4 Den Baba is gerechtigd om de overeenkomst te ontbinden indien zij
        goede grond heeft te vrezen dat Koper zijn verplichtingen niet zal
        nakomen, bijvoorbeeld vanwege ervaringen in het verleden.
      </p>
      <p className="ml-2">
        2.5 Koper en Den Baba komen uitdrukkelijk overeen dat door gebruik te
        maken van elektronische communicatievormen een geldige overeenkomst tot
        stand komt, zodra aan de voorwaarden in artikel 2.1 en 2.3 is voldaan.
        Met name ook het ontbreken van een gewone handtekening doet niet af aan
        de verbindende kracht van het aanbod en de aanvaarding daarvan. De
        elektronische bestanden van Den Baba gelden, voor zover de wet dat
        toelaat, hierbij als een vermoeden van bewijs.
      </p>
      <p className="ml-2">
        2.6 Informatie, afbeeldingen, mededelingen mondeling, telefonisch op via
        e-mail verstrekt en opgaven etcetera met betrekking tot alle
        aanbiedingen en de belangrijkste kenmerken van de producten worden zo
        nauwkeurig mogelijk (weer)gegeven of gedaan. Den Baba garandeert echter
        niet dat alle aanbiedingen en producten volledig met de gegeven
        informatie et cetera in overeenstemming zijn. Afwijkingen kunnen in
        beginsel geen aanleiding zijn tot schadevergoeding en/of ontbinding,
        tenzij de afwijking dusdanig is dat het product niet (meer) beantwoordt
        aan hetgeen de Koper daarvan redelijkerwijs had mogen verwachten in welk
        geval het gestelde in artikel 6.5 van deze Algemene Voorwaarden geldt.
      </p>
      <br></br>
      <h2 className="font-bold">3. Prijzen</h2>
      <br></br>
      <p className="ml-2">
        3.1 Alle prijzen op de site zijn "Ex Warehouse" (incoterms 1990),
        uitgedrukt in Euro's, in overeenstemming met de wettelijke voorschriften
        hieromtrent, en zijn inclusief omzetbelasting, maar exclusief
        verzendkosten, tenzij anders vermeld in de op het Product betrekking
        hebbende informatie.
      </p>
      <p className="ml-2">
        3.2 Indien het bestelde product op verzoek van de Koper buiten België
        moeten worden geleverd, dan is de Koper aansprakelijk voor betaling van
        de eventueel verschuldigde omzetbelasting of invoerrechten.
      </p>
      <p className="ml-2">
        3.3 Eventuele speciale aanbiedingen zijn slechts geldig voor de
        geldigheidsduur zoals vermeld bij die specifieke aanbieding.
      </p>
      <p className="ml-2">
        3.4 De Koper is de prijs verschuldigd die Den Baba in haar bevestiging
        conform artikel 2.3 van deze voorwaarden aan de Koper heeft medegedeeld.
        Kennelijke fouten in de prijsopgave, zoals evidente onjuistheden, dat
        wil zeggen fouten waarvan de Koper in alle redelijkheid had kunnen
        vermoeden dat er een vergissing in het spel was, kunnen ook na de
        totstandkoming van de overeenkomst door Den Baba worden gecorrigeerd.
      </p>
      <p className="ml-2">
        3.5 Den Baba behoudt zich het recht voor de bedongen prijs na het
        totstandkomen van de overeenkomst te wijzigen. Indien de prijs wordt
        verhoogd, dan heeft de Koper het recht de overeenkomst te ontbinden
        zonder gehoudenheid van Den Baba tot het betalen van schadevergoeding.
        Deze bevoegdheid komt de Koper niet toe indien is afgesproken dat de
        levering langer dan drie maanden na het totstandkomen van de
        overeenkomst zal plaatsvinden.
      </p>
      <br></br>
      <h2 className="font-bold">4. Betaling</h2>
      <br></br>
      <p className="ml-2">
        4.1 Bij bestellingen via de internetsite kan op een aantal manieren
        worden betaald. Dit staat aangegeven op de internetsite. Op deze pagina
        vind je alles over onze betalingsopties. Den Baba kan de
        betalingsmogelijkheden in de toekomst uitbreiden. Andere
        betalingsmogelijkheden zullen kenbaar worden gemaakt via de
        internetsite.
      </p>
      <p className="ml-2">
        4.2 In het geval een betalingstermijn is afgesproken, dan geldt dat door
        het enkel verstrijken van deze termijn de Koper in verzuim is.
        Betalingstermijnen kunnen uitsluitend schriftelijk worden overeengekomen
        onder alsdan te stellen en overeen te komen voorwaarden.
      </p>
      <p className="ml-2">
        4.3 In het geval voor een betaalwijze wordt gekozen met een creditcard
        dan zijn hierop de voorwaarden van de desbetreffende kaartuitgever van
        toepassing. Den Baba is geen partij in de relatie tussen de Koper en de
        kaartuitgever.
      </p>
      <p className="ml-2">
        4.4 Den Baba heeft het recht om bij gebruik van bepaalde betalingsvormen
        nadere voorwaarden te stellen ten aanzien van een minimum besteding en
        een maximum besteding. Deze nadere voorwaarden staan in dat geval op de
        internetsite.
      </p>
      <p className="ml-2">
        4.5 Bij niet of niet-tijdige betaling door Koper is per dag waarop
        betaling uiterlijk plaats had moeten vinden tot aan de dag der algehele
        voldoening over het openstaande een kost verschuldigd van 1,5% per
        maand, waarbij een ingegane maand voor een volle maand wordt gerekend.
      </p>
      <p className="ml-2">
        4.6 Ten laste van de Koper komen tevens alle (buiten-)gerechtelijke
        kosten van welke aard dan ook, die Den Baba als gevolg van de niet
        nakoming door Koper van diens (betalings-) verplichtingen heeft moeten
        maken.
      </p>
      <p className="ml-2">
        4.7 In geval van niet-tijdige betaling is Den Baba bevoegd de
        overeenkomst met onmiddellijke ingang te ontbinden of (verdere) levering
        op te schorten tot het moment waarop de Koper de betalingsverplichtingen
        volledig is nagekomen, de betaling van verschuldigde rente en kosten
        daaronder begrepen.
      </p>
      <p className="ml-2">
        4.8 Den Baba aanvaardt betalingen met BANCONTACT. Indien u kiest om te
        betalen met een bankkaart, wordt het bedrag onmiddelijk na bevestiging
        van betaling van uw kaart gedebiteerd.
      </p>
      <p className="ml-2">
        4.9 Bovendien is bij niet-tijdige betaling een forfaitaire
        schadevergoeding verschuldigd van 10% op het totale factuurbedrag, met
        een minimumbedrag van 20 euro.
      </p>
      <br></br>
      <h2 className="font-bold">5. Eigendomsvoorbehoud </h2>
      <br></br>
      <p className="ml-2">
        5.1 Eigendom van producten gaat, niettegenstaande de feitelijke
        aflevering, pas op de Koper over, nadat Koper al hetgeen Koper ter zake
        van enige overeenkomst met Den Baba is verschuldigd, volledig heeft
        voldaan, daaronder mede begrepen vergoeding van rente en kosten, ook van
        eerdere of latere leveringen en eventuele ter zake van de producten
        verrichtte of te verrichten werkzaamheden.
      </p>
      <p className="ml-2">
        5.2 De Koper mag de producten, voordat de eigendom daarvan is
        overgegaan, niet belasten, verkopen, doorleveren, vervreemden of
        anderszins bezwaren.
      </p>
      <br></br>
      <h2 className="font-bold">6. Aansprakelijkheid</h2>
      <br></br>
      <p className="ml-2">
        6.1 Den Baba kan slechts aansprakelijk gehouden worden voor schade, die
        aan haar opzet of grove schuld is toe te rekenen, dan wel ontstaan is
        door omstandigheden die op grond van dwingendrechtelijke regels voor
        haar risico komen. Den Baba is nimmer aansprakelijk voor gevolg- of
        bedrijfsschade, indirecte schade en winst- of omzetderving.
      </p>
      <p className="ml-2">
        6.2 Indien Den Baba, om welke reden dan ook, gehouden is enige schade te
        vergoeden dan zal de schadevergoeding nooit hoger zijn dan een bedrag
        gelijk aan de factuurwaarde met betrekking tot het product of dienst
        waardoor de schade is veroorzaakt. Den Baba is nimmer gehouden tot
        schadevergoeding anders dan zaak- en/of personenschade.
      </p>
      <p className="ml-2">
        6.3 De Koper is gehouden Den Baba te vrijwaren voor enige aanspraak die
        derden ter zake van de uitvoering van de overeenkomst tegen Den Baba
        mochten doen gelden, voor zover de wet er zich niet tegen verzet dat de
        desbetreffende schade en kosten voor rekening van de Koper dienen te
        komen.
      </p>
      <p className="ml-2">
        6.4 Het is mogelijk Den Baba op haar internetsite links opneemt naar
        andere internetsites die mogelijk interessant of informatief voor de
        bezoeker kunnen zijn. Dergelijke links zijn louter informatief. Den Baba
        is niet verantwoordelijk voor de inhoud van de internetsite waarnaar
        wordt verwezen of het gebruik dat ervan kan worden gemaakt.
      </p>
      <p className="ml-2">
        6.5 Het in dit artikel bepaalde laat onverlet de wettelijke
        aansprakelijkheid van Den Baba ingevolge dwingendrechtelijke bepalingen.
      </p>
      <br></br>
      <h2 className="font-bold">7. Overmacht</h2>
      <br></br>
      <p className="ml-2">
        7.1 In geval van overmacht is Den Baba niet gehouden haar verplichtingen
        jegens de Koper na te komen, respectievelijk wordt de verplichting tot
        nakoming opgeschort voor de duur van de overmacht.
      </p>
      <p className="ml-2">
        7.2 Onder overmacht wordt verstaan elke van de wil van Den Baba
        onafhankelijke omstandigheid, waardoor de nakoming van haar
        verplichtingen jegens de Koper geheel of gedeeltelijk wordt verhinderd.
        Tot die omstandigheden behoren onder meer stakingen, brand,
        bedrijfsstoringen, energiestoringen, niet of niet-tijdige levering van
        toeleveranciers of andere ingeschakelde derden en het ontbreken van
        enige via overheidswege te verkrijgen vergunning. Tevens wordt onder
        overmacht verstaan storingen in een (telecommunicatie-)netwerk of
        verbinding of gebruikte communicatiesystemen en/of het op enig moment
        niet beschikbaar zijn van de internetsite.
      </p>
      <br></br>
      <h2 className="font-bold">8. Intellectuele eigendom</h2>
      <br></br>
      <p className="ml-2">
        8.1 De Koper erkent uitdrukkelijk dat alle rechten van intellectuele
        eigendom van weergegeven informatie, mededelingen of andere uitingen met
        betrekking tot de producten en/of met betrekking tot de internetsite
        berusten en blijven berusten bij Den Baba, haar toeleveranciers of
        andere rechthebbenden. Met de overeenkomst komt derhalve geen overdacht
        van intellectuele eigendomsrechten tot stand.
      </p>
      <p className="ml-2">
        8.2 Het is de Koper verboden gebruik te maken, daaronder het aanbrengen
        van wijzigingen begrepen, van de intellectuele eigendomsrechten
        behorende bij het gekochte product, zoals bijvoorbeeld clooning, zonder
        uitdrukkelijke voorafgaande schriftelijke toestemming van Den Baba, haar
        toeleveranciers of andere rechthebbenden, tenzij het louter gaat om
        privé gebruik in relatie tot het product zelf.
      </p>
      <br></br>
      <h2 className="font-bold">9. Persoonsgegevens</h2>
      <br></br>
      <p className="ml-2">
        9.1 Den Baba zal de gegevens van de Koper uitsluitend verwerken in
        overeenstemming met haar privacy beleid. De internetsite bevat een
        uitgebreid Privacy Statement.
      </p>
      <p className="ml-2">
        9.2 Den Baba neemt daarbij de van toepassing zijnde privacyregel- en
        wetgeving in acht.
      </p>
      <br></br>
      <h2 className="font-bold">10. Toepasselijk recht en bevoegde rechter</h2>
      <br></br>
      <p className="ml-2">10.1 De Belgische wetgeving is van toepassing.</p>
      <p className="ml-2">
        10.2 De toepasselijkheid van het Weens Koopverdrag wordt uitdrukkelijk
        uitgesloten.
      </p>
      <p className="ml-2">
        10.3 Uitsluitend de rechtbanken van het gerechtelijk arrondissement Oost
        Vlaanderen, afdeling Dendermonde zijn bevoegd.
      </p>
      <br></br>
      <h2 className="font-bold">11. Geschillenregeling en beslechting</h2>
      <br></br>
      <p className="ml-2">
        11.1 Indien de Koper een consument is, bestaat daarnaast de mogelijkheid
        om een geschil voor te leggen aan de online bemiddelingsdienst van
        Mollie, mits het gaat om een aanbieding of overeenkomst die via de
        internetsite of telefoonverbinding is gedaan of tot stand gekomen.
      </p>
      <br></br>
      <h2 className="font-bold">12. Diversen</h2>
      <br></br>
      <p className="ml-2">
        12.1 Den Baba is gevestigd te 9300 AALST, Gentsesteenweg 129 en
        geregistreerd bij de Kamer van Koophandel. Het BTW-identificatienummer
        is BE 0726.981.643 Gelieve alle correspondentie aangaande een
        overeenkomst of deze voorwaarden te zenden aan Den Baba , Gentsesteenweg
        129, 9300 Aalst of via email naar info@denbaba.be. Dit is ook het
        bezoekadres in geval van klachten.
      </p>
      <p className="ml-2">
        12.2 Den Baba streeft ernaar om de door haar ontvangen e-mails gedurende
        de bereikbaarheidstijden van de Den Baba Helpdesk te beantwoorden.
      </p>
      <br></br>
      <h2 className="font-bold">13. Nederlandse tekst prevaleert</h2>
      <br></br>
      <p className="ml-2">
        13.1 De Nederlandse tekst van deze algemene voorwaarden prevaleert boven
        vertalingen daarvan.
      </p>
      <br></br>
      <h2 className="font-bold">14. Registratieplicht Covid-19</h2>
      <br></br>
      <p className="ml-2">
        14.1 Juridisch: op welke basis verwerken we je gegevens? Je hebt vast al
        gehoord over de ‘GDPR’: de ‘General Data Protection Regulation’. Dat is
        een strenge Europese regelgeving die ervoor zorgt dat je privacy beter
        is beschermd. We verwerken je gegevens, direct in opdracht van de
        overheid, en in lijn met de beslissing van de Nationale Veiligheidsraad,
        op grond van artikel 6, c (“noodzakelijke verwerking om te voldoen aan
        een wettelijke verplichting die op de verwerking verantwoordelijke
        rust”) en artikel 3, 10° Ministerieel besluit van 24 juli 2020 houdende
        wijziging van het ministerieel besluit van 30 juni 2020 houdende
        dringende maatregelen om de verspreiding van het coronavirus COVID-19 te
        beperken. Indien je vragen hebt over deze verwerking, of je wil een
        verzoek dan wel klacht indienen, kan je de Data Protection Officer van
        het Agentschap Zorg & Gezondheid contacteren via
        veiligheidsconsulent.zg@vlaanderen.be.
      </p>
      <br></br>
      <p className="ml-2 italic">Den Baba, 25 juli 2022.</p>
    </div>
  );
};

export default TermsOfUse;
