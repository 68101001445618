import { db, firestoreTimestamp } from "../firebase";

export const addOrderToDB = (order) => {
  return db
    .collection("orders")
    .add({ ...order, created: firestoreTimestamp.now() });
};

export const updateOrderInDB = (id, order) => {
  return db
    .collection("orders")
    .doc(id)
    .set({ ...order, created: firestoreTimestamp.now() });
};
