export const SET_DELIVERY = "SET_DELIVERY";
export const SET_SUCCESS_ORDER = "SET_SUCCESS_ORDER";
export const SET_FAILED_ORDER = "SET_FAILED_ORDER";
export const REMOVE_ERROR = "REMOVE_ERROR";

export const setDelivery = (isDelivery) => {
  return {
    type: SET_DELIVERY,
    payload: isDelivery,
  };
};

export const setSuccessOrder = () => {
  return {
    type: SET_SUCCESS_ORDER,
  };
};

export const removeError = () => {
  return {
    type: REMOVE_ERROR,
  };
};

export const setFailedOrder = (failedOrder) => {
  return {
    type: SET_FAILED_ORDER,
    payload: failedOrder,
  };
};
