import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import React from "react";

import SluitingImg from "../assets/img/sluiting.jpg";

const PromoDialog = ({ open, setOpen }) => {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      maxWidth="xl"
      fullWidth>
      <DialogTitle style={{ color: "#ffc107" }}>BELANGRIJK</DialogTitle>
      <DialogContent
        dividers
        style={{ display: "flex", justifyContent: "center" }}>
        <img
          src={SluitingImg}
          style={{ objectFit: "contain" }}
          alt="sluiting"
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen((prev) => !prev);
            sessionStorage.setItem("closePopup", 0);
          }}>
          Sluiten
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromoDialog;
